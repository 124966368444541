.card {
  &__container {
    background: #fff;
    box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.1);
    padding: 30px 60px 60px 40px;
    @include mobile {
      padding: 0;
      box-shadow: none;
    }
  }
  &__small {
    background: #fff;
    padding: 20px;
    box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.1);
    &__body {
      display: flex;
      align-items: center;
    }
    &__img {
      margin-right: 20px;
      width: 110px;
      height: 110px;
      object-fit: contain;
      &-responsive {
        @extend .card__small__img;
        @include mobile {
          width: 70px;
          height: 70px;
        }
      }
    }
  }
}

.card {
  &__block-with-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 28px;
    padding: 0 1rem;
    .item {
      &--icon-block {
        border-radius: 100%;
        height: 134px;
        width: 134px;
        color: #fff;
        background-color: $agency;
        font-size: 68px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        &.success-create-account {
          font-size: 64px;
        }
      }

      &--headline {
        font-size: 30px;
        font-weight: 600;
        line-height: 41px;
        letter-spacing: 0em;
        text-align: center;
        color: #50524e;
        margin-bottom: 8px;
      }

      &--description {
        font-size: 14px;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: 30px;
        color: #929292;
      }
    }
  }
}
