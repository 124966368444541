.app-dropdown {
  position: relative;

  & > button {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &--label {
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 8px;
    z-index: 1;
  }

  &--placeholder {
    color: var(--input-placeholder-color);
    z-index: 10;
    font-weight: 500;
  }

  &--list-wrapper{
    overflow: hidden;
    max-height: 300px;
    position: absolute;
    top: 36px;
    border: 1px solid var(--input-bg-color);
    border-radius: var(--radius-lg);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 0;
    z-index: 11;
    background-color: var(--secondary-color);
    min-width: 100%;
  }

  &--list-container {
    background-color: transparent;
    display: flex;
    flex-direction: column;
    width: auto;
    padding: 12px 12px 0;
    min-width: 100%;
    white-space: nowrap;
    max-height: 300px;
    overflow: auto;
  }

  &--list-item {
    text-align: left;
    background-color: transparent;
    border: unset;
    color: var(--text-secondary-color);
    line-height: 43px;
    border-top: 1px solid var(--primary-color);
    max-width: 100%;
    white-space: normal;
    font-weight: 500;

    &:hover {
      background-color: var(--primary-color);
      color: var(--text-primary-color);;
    }
  }

  .chevron-icon {
    transform: rotate(0);
    padding-left: 8px;
    &.active {
      background-color: unset;
      transform: rotate(180deg) translateX(-9px);
    }
  }
}
