.edit-profile-picture {
    cursor: pointer;
    overflow: hidden;
    position: relative;
    width: 100%;
    margin: auto;

    @media only screen and (min-width: 576px) {
        width: 100%;
        margin: 0;
    }

    &__overlay {
        min-width: 100%;
        min-height: 100%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
        top: 0px;
        background-color: rgba(0,0,0,0.5);
        transition: 200ms all ease-in-out;
        cursor: pointer;
        z-index: 2;
    }

    &__hover {
        transform: scale(1.3);
    }

    &__text {
        margin-bottom: 0px;
        font-weight: 500;
    }

    &__smallprint {
        font-size: 12px;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 0px;

        @media only screen and (min-width: 576px) {
            width: 100%;
        }
    }
}
