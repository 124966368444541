// bottom margins
.mb-0 {
    margin-bottom: 0 !important;
}
.mb-10 {
    margin-bottom: 10px !important;
}
.mb-12 {
    margin-bottom: 12px !important;
}
.mb-14 {
    margin-bottom: 14px !important;
}
.mb-17 {
    margin-bottom: 17px !important;
}
.mb-20 {
    margin-bottom: 20px !important;
}
.mb-30 {
    margin-bottom: 30px !important;
}
.mb-40 {
    margin-bottom: 40px !important;
}
.mb-50 {
    margin-bottom: 50px !important;
}
.mb-60 {
    margin-bottom: 60px !important;
}
.mb-70 {
    margin-bottom: 70px !important;
}
.mb-100 {
    margin-bottom: 100px !important;
}
// top margins
.mt-0 {
    margin-top: 0 !important;
}
.mt-10 {
    margin-top: 10px !important;
}
.mt-20 {
    margin-top: 20px !important;
}
.mt-30 {
    margin-top: 30px !important;
}
.mt-40 {
    margin-top: 40px !important;
}
.mt-42 {
    margin-top: 42px !important;
}
.mt-50 {
    margin-top: 50px !important;
}
@media only screen and (min-width: 1024px) {
    .mt-lg-42 {
        margin-top: 42px !important;
    }
}

// ---- padding bottom
.pb-0 {
    padding-bottom: 0 !important;
}
// ---- padding x-axis
.px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
// ---- padding y-axis
.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
// padding all
.p-20 {
    padding: 20px;
}

.mt-16 {
    margin-top: 1rem;
}

.mt-24 {
    margin-top: 24px;
}

.mt-36 {
    margin-top: 36px;
}

.mt-60 {
    margin-top: 60px !important;
}

.mt-100 {
    margin-top: 100px;
}

.mb-16 {
    margin-bottom: 1rem;
}

.pb-50 {
    padding-bottom: 50px;
}