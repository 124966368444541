.profile {
  &-layout {
    --p-space-y: 13px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-top: var(--section-space);

    @include mq(lg) {
      flex-direction: row;
    }

    &.single-grid {
      & > .l-side,
      & > .r-side {
        max-width: 100%;
        margin-left: 0;
      }
      & > .r-side {
        max-width: 100%;
        margin-left: 0;
        margin-top: var(--section-space);
      }
    }

    & > .l-side {
      flex: 0 0 100%;
      max-width: 100%;

      @include mq(lg) {
        flex: 0 0 100%;
        max-width: 247px;

        &.left-wide {
          max-width: 275px;
        }
      }
    }

    & > .r-side {
      flex: 0 0 100%;
      max-width: 100%;
      margin-left: 0;

      @include mq(lg) {
        flex: 0 0 439px;
        max-width: 439px;
        margin-left: 66px;

        &.full-size {
          flex: 1 1 0;
          max-width: 100%;
        }
      }
    }

    &-other {
      display: block;

      @include mq(lg) {
        display: flex;
      }

      .hide-on-desktop {
        @include mq(lg) {
          display: none;
        }
      }

      .hide-on-mobile {
        display: none;
        @include mq(lg) {
          display: block;
        }
      }

      & > .l-side {
        flex: 0 0 100%;
        max-width: 100%;

        @include mq(lg) {
          flex: 0 0 50%;
          max-width: 50%;
        }

        // h1,
        // h2,
        // h3 {
        //   margin-left: 36px;
        // }
      }
      & > .r-side {
        flex: 0 0 100%;
        max-width: 100%;
        margin-left: 0;

        @include mq(lg) {
          flex: 0 0 50%;
          max-width: 50%;

          &.full-size {
            flex: 1 1 0;
            max-width: 100%;
          }
        }
      }
    }
  }
}

.profile-block {
  &--avatar {
    margin-bottom: 28px;
    .avatar-meta {
      &-name {
        margin-bottom: var(--p-space-y);
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
      }
      &-image {
        border-radius: var(--radius-lg);
        overflow: hidden;

        &-square {
          border-radius: 0;
        }

        &.full-rounded {
          border-radius: 999px;

          img {
            border-radius: 999px;
          }

          .frame-substract {
            &.no-frame {
              .app-btn-modal-close {
                right: 40px;
                top: 40px;
                left: unset;
              }
            }
          }
        }
      }
    }
    .frame-substract {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &-other {
    &--avatar {
      margin-bottom: 28px;
      // @include mq(lg) {
      max-width: 72px;
      // }

      .avatar-meta {
        &-name {
          margin-bottom: var(--p-space-y);
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
        }
        &-image {
          border-radius: var(--radius-lg);
          overflow: hidden;

          &.full-rounded {
            border-radius: 999px;

            img {
              border-radius: 999px;
            }
          }
        }
      }
      .frame-substract {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    &--vetted {
      position: relative;
      width: 100px;
      display: flex;
      align-items: center;
      right: -60px;
      top: -25px;

      svg {
        width: 20px;
        height: 20px;
      }

      span {
        margin-left: 10px;
        background-color: var(--first-gradient-color);
        background-size: 100% 100%;
        background-repeat: repeat;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -webkit-font-smoothing: antialiased;
      }
    }
  }

  &--vetted {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 20px;
      height: 20px;
    }

    span {
      margin-left: 10px;
      background-color: var(--first-gradient-color);
      background-size: 100% 100%;
      background-repeat: repeat;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -webkit-font-smoothing: antialiased;
    }
  }
}

.profile-company {
  h3.meta--heading {
    margin-bottom: 25px;
  }
}

.profile-details {
  &--grid {
    display: flex;
    flex-direction: column;
    margin-left: -16px;
    margin-right: -16px;
    gap: 50px;
    @include mq(lg) {
      gap: unset;
      flex-direction: row;
    }

    &-item {
      flex: 0 0 50%;
      text-align: left;
      padding-left: 16px;
      padding-right: 16px;

      &:first-child {
        margin-top: 59px;
        @include mq(lg) {
          margin-top: 0;
        }
      }

      & > .meta-item {
        margin-top: 51px;
        margin-bottom: 0;
        &:first-child {
          margin-top: 0;
        }

        & > * {
          margin-top: 23px;
          margin-bottom: 0;
          &:first-child {
            margin-top: 0;
          }
        }
      }

      &[data-full-left='true'] {
        flex: 0 0 100%;
      }
    }
    &-item--full {
      flex: 0 0 100%;
    }

    &.column {
      flex-direction: column !important;
    }
  }
}

.big-chip {
  .meta--content {
    font-size: 14px;
    font-weight: 400;
    line-height: 20.23px;
    margin-bottom: 51px;
  }

  .meta--chip {
    font-size: 20px;
    font-weight: 600;
    line-height: 28.9px;
    text-align: center;
    padding: 15px 30px;
    color: var(--text-primary-color);
    border: 1px solid var(--text-primary-color);
    border-radius: 100px;
    display: inline-flex;
  }
}

.meta-item {
  &[data-max-width='50'] {
    @include mq(lg) {
      max-width: 50%;
    }
  }
  &[data-max-width='439'] {
    @include mq(lg) {
      max-width: 439px;
    }
  }
}

.purchase-checkout {
  display: flex;
  flex-wrap: wrap;
  &--row {
    border-bottom: 1px solid #ffffff;
    font-size: 14px;
    font-weight: 500;
    line-height: 10px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    flex: 0 0 100%;
    max-width: 100%;
    flex-wrap: wrap;

    &.row-header {
      color: #aaaaaa;
    }

    &.no-border {
      border-bottom-color: transparent;
      .col-meta-data {
        padding-bottom: 2px;
      }
    }
  }

  .col-meta-data {
    padding: 12px 21px;
  }

  .col-name {
    flex: 2 1 0;
    text-align: left;
  }
  .col-value {
    flex: 1 1 0;
    text-align: right;
  }
}

.purchasing-selection-cta {
  display: flex;
  gap: 13px;

  & > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.profile-action {
  &--block {
    a {
      text-transform: uppercase;
    }
  }
}
