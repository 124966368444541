.text {
  color: var(--text-primary-color);
  &--leading {
    font-family: var(--font-headinng);
    font-size: 20px;
    font-weight: 900;
    line-height: 14px;
  }

  &--capitalize {
    text-transform: capitalize !important;
  }

  &--p,
  &--regular,
  &--default {
    font-family: var(--font-body);
    font-size: 14px;
    font-weight: 400;
    line-height: 20.23px;

    p {
      margin-bottom: 1rem !important;
    }

    ul,
    ol {
      font-size: 16px;
      font-weight: 300;
    }
  }

  &--h1,
  &--h2 {
    font-family: var(--font-headinng);
    font-weight: var(--font-bold);
    margin-bottom: 0;
  }
  &--h3 {
    font-family: var(--font-headinng);
    font-weight: 900;
    margin-bottom: 0;
    font-size: 20px;
    line-height: 28.9px;

    &.up-size {
      font-size: 22px;
      line-height: 24px;
      letter-spacing: 0.1px;

      @include mq(lg) {
        font-size: 24px;
      }
    }
  }
  &--h1 {
    font-size: 32px;
    line-height: 1.4em;
    @include mq(lg) {
      font-size: 40px;
    }
  }
  &--h2 {
    font-size: 24px;
    @include mq(lg) {
      font-size: 30px;
    }
  }

  &--label1 {
    font-size: 14px;
    font-weight: var(--font-medium);
    @include mq(lg) {
      font-size: 16px;
    }
  }
  &--label2 {
    font-size: 14px;
    font-weight: var(--font-bold);
    @include mq(lg) {
      font-size: 16px;
    }
  }
  &--span {
    font-size: 16px;
    line-height: 23.12px;
    font-family: var(--font-body);
    font-weight: 400;
    @include mq(lg) {
      font-size: 20px;
      line-height: 28.9px;
      text-align: left;
    }
  }

  &--small {
    font-size: 12px;
    font-weight: 400;
    line-height: 17.34px;
  }

  &--xs {
    font-size: 14px;
  }
  &--lg {
    font-size: 20px;
  }
  &--md {
    font-size: 18px;
  }
  &--med {
    font-weight: 500;
  }

  &--bold {
    font-weight: 700;
  }

  &--grey {
    color: #aaaaaa;
  }

  &-error {
    color: var(--error-primary);
  }
  &-disabled {
    color: var(--text-disabled-color);
  }

  &--400 {
    font-weight: 400 !important;
  }

  &--700 {
    font-weight: 700 !important;
  }

  &-underline {
    text-decoration: underline;
  }
  &-count {
    font-weight: 400;
    font-size: 16px;
    @include mq(lg) {
      font-size: 20px;
    }
  }
  &--spacing-1 {
    letter-spacing: 1px !important;
  }
}

a {
  color: var(--text-primary-color);
  text-decoration: none;

  &.underline {
    text-decoration: underline;
  }

  &:hover {
    color: var(--text-primary-color);
    text-decoration: none;
  }
}

.text_withbg {
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  position: relative;
  color: transparent;
  &::before {
    content: attr(data-text);
    position: absolute;
    background-image: var(--btn-bg-xl);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    background-size: cover;
    background-position: center;
    height: 100%;
  }
}

.text--mesh {
  background-color: var(--first-gradient-color);
  background-image: var(--text-bg);
  background-size: 100% 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-font-smoothing: antialiased;
}
