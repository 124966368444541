.gridcasestudies-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 24px;
  margin-left: -12px;
  margin-right: -12px;
  row-gap: 60px;

  @include mq(lg) {
    flex-wrap: wrap;
    margin-left: -11px;
    margin-right: -11px;
    margin-top: 40px;
  }

  &--item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;

    @include mq(lg) {
      flex: 0 0 25%;
      padding-left: 11px;
      padding-right: 11px;
      margin-bottom: 0;
    }
  }

  &--image {
    padding-top: 80.32%;
    overflow: hidden;
    position: relative;
    border-radius: 1px;

    & > img {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &--btn {
    width: 122px !important;
    padding-block: 14px;
    margin-top: 15px;
    height: 37px;
    font-weight: 700;
  }
}

.grid-item {
  // Please add specific class here because this duplicated for the other
  &--image {
    padding-top: 80.32%;
    overflow: hidden;
    position: relative;
    border-radius: 1px;

    & > img {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &--divider {
    height: 1px;
    border-top: 1px solid #fff;
    margin-top: 14px;
    margin-bottom: 14px;
  }

  &--hedline {
    font-size: 14px;
    font-weight: normal;
    line-height: 10px;
  }

  &--description {
    font-size: 14px;
    font-weight: normal;
    line-height: 20.23px;
    height: 50px;

    p {
      max-height: 40px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &--title {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-transform: capitalize;
  }
  &--title-only {
    font-size: 14px;
    font-weight: 400;
    text-transform: capitalize;
  }
}
