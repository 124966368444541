.filter-agency {
  margin-bottom: 23px;
  margin-top: 33px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @include mq(lg) {
    margin-bottom: 19px;
    margin-top: 40px;
  }

  & > span {
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;

    &.active {
      font-weight: 700;
    }
  }

  & > * + * {
    margin-left: 7px;
  }
}

.agency-list-view {
  display: flex;
  flex-direction: column;

  & > .agency-list--item {
    --image-size: 48px;

    @include mq(lg) {
      --image-size: 57px;
    }
    margin-bottom: 12px;
    background-color: var(--primary-color);
    color: var(--text-primary-color);
    padding: 18px 18px 25px;
    border-radius: 26px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    @include mq(lg) {
      align-items: center;
      margin-bottom: 8px;
      padding: 8px 17px 8px 8px;
      flex-direction: row;
      border-radius: var(--radius-lg);
    }
    .list-item {
      &--meta-media,
      &--meta-information {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        @include mq(lg) {
          align-items: center;
        }
      }

      &--meta-media {
        flex: 0 0 var(--image-size);

        .featured-image--frame {
          width: var(--image-size);
          height: var(--image-size);
          overflow: hidden;
          border-radius: 999px;
          display: flex;
          align-items: center;
          justify-content: center;

          & > .featured-image--asset {
            width: 100%;
            height: auto;
            object-fit: contain;
          }
        }
      }
      &--meta-name-location {
        margin-bottom: 13px;
        @include mq(lg) {
          max-width: 180px;
          flex: 0 0 180px;
          margin-bottom: 0;
        }
        @include mq(xl) {
          max-width: 215px;
          flex: 0 0 215px;
        }
      }
      &--meta-name {
        font-size: 18px;
        font-weight: 900;
        line-height: 13px;
        margin-bottom: 9px;
      }
      &--meta-location {
        font-size: 14px;
        font-weight: 600;
        line-height: 11px;
      }
      &--meta-information {
        padding-left: 18px;
        flex: 0 0 100%;
        max-width: calc(100% - var(--image-size));
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        flex-direction: column;
        padding-right: 16px;
        @include mq(lg) {
          padding-left: 16px;
          padding-right: 0;
          flex-direction: row;
          align-items: center;
        }
      }
      &--meta-skils {
        margin-bottom: 20px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        @include mq(lg) {
          margin-bottom: 0;
          max-width: 480px;
          flex: 0 0 480px;
          flex-direction: row;
        }
        @include mq(xl) {
          max-width: 677px;
          flex: 0 0 677px;
        }

        & > span {
          line-height: 21px;
          &::before {
            display: inline-block;
            content: '•';
            padding-right: 5px;
          }
          display: inline-flex;
          align-items: center;
          padding-right: 8px;
        }
      }
    }
  }
}
