.available-credits {
    background-color: #F0F6F8;
    text-align: center;
    margin-bottom: 10px;
    display: inline-block;
    width: 100%;

    &__heading {
        font-size: 40px;
        font-weight: 700;
        margin: 20px 0px 0px 0px;
    }

    &__subheading {
        font-size: 18px;
        font-weight: 300;
        margin: 0px 0px 20px 0px;
    }

}
