.dashboard-nav {
    display: none;

    @media only screen and (min-width: 992px) {
        display: block;
        background-color: #F8F8F8;
        border-radius: 50px;
        margin-bottom: 50px;
    }

    &__menu {
        display: flex;
        align-items: center;
        padding-left: 35px;

    }

    &__button {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &__ul {
        list-style-type: none;
        margin-bottom: 0px;
        padding-inline-start: 0px;

        &__li {
            display: inline-block;
            margin-right: 30px;
        }
    }
}
