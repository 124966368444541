.checkbox__bar {
    input {
        position: absolute;
        left: -9999px;

        &:checked + label {
            background-color: #c0b5d6;
            border-left: 5px solid #8c79b5;
            color: #fff;
            font-weight: bold;
        }

        &:checked + label::before {
            background-color: #8c79b5;
            border: 1px solid #fff;
        }
        &:checked + label::after {
            display: block;
        }
    }
    label {
        display: block;
        position: relative;
        padding: 20px;
        margin-bottom: 20px;
        background-color: #f4f2f8;
        cursor: pointer;
        user-select: none;
        transition: background-color 0.2s;
        height: 64px;
        width: 100%;
        border-left: 5px solid #c0b5d6;
        
        &::after {
            content: "";
            position: absolute;
            right: 32px;
            top: 20px;
            width: 10px;
            height: 18px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 15px;
            bottom: 15px;
            right: 20px;
            width: 34px;
            height: 34px;
            border: 1px solid #c0b5d6;
            background: #fff;
            border-radius: 50%;
            transition: background-color 0.2s;
        }

        &:hover {
            background-color: #c0b5d6;
            border-left: 5px solid #8c79b5;
            color: #fff;
            font-weight: bold;
        }
    }
}
