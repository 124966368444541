.img {
  display: inline-block;

  &__logo {
    display: block;

    &__mobile {
      max-width: 100px;

      @media only screen and (min-width: 576px) {
        display: none;
      }
    }

    &__desktop {
      display: none;
      max-width: 150px;

      @media only screen and (min-width: 576px) {
        display: block;
      }
    }

    &__footer {
      max-width: 150px;
      width: 150px;
      height: 50px;
      object-fit: cover;
    }
  }

  &__social {
    max-width: 30px;
    transition: 150ms all ease-in-out;

    &__share {
      max-width: 25px;
      transition: 150ms all ease-in-out;
    }

    &__share:hover {
      transform: scale(1.075);
    }
  }

  &__social:hover {
    transform: scale(1.075);
  }

  &__resource {
    &__thumbnail {
      width: 100%;
      transition: 250ms all ease-in-out;
      scale: 1;
    }
  }

  &__training {
    &__thumbnail {
      width: 100%;
      height: 343px;
      transition: 250ms all ease-in-out;
      transform: scale(1);
      object-fit: cover;
      @media (max-width: 1024px) {
        height: 285px;
      }
      @media (max-width: 768px) {
        height: 437px;
      }
      @media (max-width: 414px) {
        height: 243px;
      }
    }
  }

  &__mentoring {
    &__thumbnail {
      width: 100%;
      height: 343px;
      transition: 250ms all ease-in-out;
      transform: scale(1);
      @media (max-width: 1024px) {
        height: 285px;
      }
      @media (max-width: 768px) {
        height: 437px;
      }
      @media (max-width: 414px) {
        height: 243px;
      }
    }
  }

  &__profile {
    width: 100%;
    transition: 150ms all ease-in-out;

    @media only screen and (min-width: 576px) {
    }

    &__hover {
      transform: scale(1.1);
    }
  }

  &__company-logo {
    width: 100%;
    transition: 150ms all ease-in-out;

    &__hover {
      transform: scale(1.1);
    }
  }
}

.cta-hover {
  transform: scale(1.07);
}
