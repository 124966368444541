.agency_switch {
    .react-switch-bg {
        background-image: url('/img/agency-gradients/Agency-mesh-gradient-1.jpg') !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        background-position: center !important;
    }
}

.app-switch-checkbox {
    --transition-duration: 320ms;
    margin-bottom: 0;
    display: inline-flex;
    cursor: pointer;
    
    input[type="checkbox"]{
      display: none;
    }
  
    input[type="checkbox"]:checked ~.switch-slider {
     background-image: var(--switch-bg);
     background-color: var(--first-gradient-color);
     .switch-slider--bullet {
      left: calc(100% - 14px)
     }
    }
  
    .switch-slider {
      position: relative;
      width: 30px;
      height: 16px;
      border-radius: 50px;
      background-color: #AAAAAA;
      display: block;
      margin-bottom: 0;
      transition: background var(--transition-duration);
      background-repeat: no-repeat;
      background-size: 100% 100%;
  
  
      .switch-slider--bullet {
        position: absolute;
        width: 12px;
        height: 12px;
        top: 2px;
        left: 2px;
        background-color:var(--secondary-color);
        border-radius: 10px;
        transition: background var(--transition-duration), left var(--transition-duration);
   
      }
  
      &.no-change-inactive {
        background-image: var(--switch-bg);
        background-color: var(--first-gradient-color);
  
        .switch-slider--bullet {
          background-color: var(--secondary-color);
        }
      }
    }
  }
  