.submenu {
  &-navigation {
    --space-x: 12px;
    --minus-x: -1;


    display: flex;
    flex-wrap: wrap;
    margin-top: 33px;
    margin-left: calc(var(--space-x) / 2 * var(--minus-x));
    margin-right: calc(var(--space-x) / 2 * var(--minus-x));
    margin-bottom: calc(var(--space-x) * var(--minus-x));
    @include mq(lg) {
      margin-top: 40px;
    }

    &.no-top-space {
      margin-top: 0;
    }
    &--wrapper {
      padding-left: calc(var(--space-x) / 2);
      padding-right: calc(var(--space-x) / 2);
      padding-bottom: var(--space-x);
      & > a {
        @include mq(lg) {
          padding: 8px 15px;
        }
      }
    }
  }
}
