.bg {
  &-agency {
    background-color: $agency !important;
  }
  &-company {
    background-color: $company !important;
  }
  &-black {
    background-color: #000000 !important;
  }
  &-freelancer {
    background-color: #8c79b5 !important;
  }
  &-light-agency {
    background-color: var(--secondary-brand, $agency-light )!important;
  }
  &-light-freelancer {
    background-color: #f4f2f8 !important;
  }
  &-white {
    background-color: #ffffff !important;
  }
  &-red {
    background-color: #FF2A2A !important;
  }
  &-freelancer-outline {
    border: 2px solid;
    background-color: #ffffff !important;
    color: #8c79b5 !important;
    border-color: #8c79b5 !important;
  }
  &__gradient-1 {
    background-image: url("/img/agency-gradients/Agency-mesh-gradient-1.jpg");
  }

  &__gradient-2 {
    background-image: url("/img/agency-gradients/Agency-mesh-gradient-2.jpg");
  }

  &__gradient-3 {
    background-image: url("/img/agency-gradients/Agency-mesh-gradient-3.jpg");
  }

  &__gradient-4 {
    background-image: url("/img/agency-gradients/Agency-mesh-gradient-4.jpg");
  }

  &__gradient-5 {
    background-image: url("/img/agency-gradients/Agency-mesh-gradient-5.jpg");
  }
    &__gradient-6 {
    background-image: url("/img/agency-gradients/Agency-mesh-gradient-6.jpg");
  }
  &-light {
    background-color: var(--primary-brand-20 )!important;
  }
  &-center {
    background-position: center;
  }
  &-cover {
    background-size: cover;
  }
  &-top {
    background-position: top;
  }
  &-fixed {
    background-attachment: fixed;
  }
}

.bg-image {
  &-unset {
    background-image: unset;
  }
}

a.agency-link-color {
  color: #237b9f;
}

.hover-cursor {
  &:hover {
    cursor: pointer;
    color: #8c79b5;
  }
}

.link-no-effects {
  color: inherit;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
}
