.training {

    &__card {
        position: relative;
        display: inline-block;
        margin-bottom: 30px;
        overflow: hidden;

        &__title {
            position: absolute;
            font-size: 22px;
            line-height: 26px;
            left: 20px;
            bottom: 20px;
            padding-right: 20px;
            font-weight: 500;
            color: #FFFFFF;
            z-index: 1;

            @media only screen and (min-width: 576px) {
                left: 40px;
                bottom: 40px;
                padding-right: 40px;
                font-size: 30px;
                line-height: 36px;
            }

            @media only screen and (min-width: 768px) {
                left: 40px;
                bottom: 40px;
                padding-right: 40px;
                font-size: 30px;
                line-height: 36px;
            }
        }
    }

    &__card:after {
        content:'';
        position:absolute;
        left:0; top:0;
        width:100%; height:100%;
        display:inline-block;
        background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.75));
    }
}
