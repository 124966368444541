.chips {
  .chip {
    background-color: #8c79b5;
    width: max-content;
    padding: 4px 10px;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 10px;
    display: inline-flex;
    align-items: center;
    font-size: 13px;
    color: #fff;
    white-space: nowrap;

    @media (max-width: 1024px) {
      font-size: 11px;
    }
  }
}
