.published {
    display: flex;

    &__text {
        display: flex;
        align-items: center;
        width: 75%;
    }

    &__toggle {
        display: flex;
        align-items: center;
        width: 25%;
    }
}
