.edit-company-logo {
    cursor: pointer;
    overflow: hidden;
    position: relative;

    &__overlay {
        min-width: 100%;
        min-height: 100%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
        top: 0px;
        background-color: rgba(0,0,0,0.5);
        transition: 200ms all ease-in-out;
        cursor: pointer;
        z-index: 2;
    }

    &__text {
        margin-bottom: 0px;
        font-weight: 500;
    }
}
