.button {
  background: black;
  display: block;
  width: 100%;

  &__inner {
    margin: 0 auto;
    max-width: 100px;

    &__title {
      color: white;
    }
  }

  &__highlight-white {
    border: 2px solid #ffffff;
    color: #ffffff;
    display: inline-block;
    padding: 7px 20px;
    border-radius: 50px;
    text-decoration: none;
    transition: 250ms all ease-in-out;
  }

  &__highlight-white:hover {
    background-color: #ffffff;
    color: #51524f;
    text-decoration: none;
  }

  &__highlight-blue {
    border: 2px solid #237b9f;
    color: #237b9f;
    display: inline-block;
    padding: 7px 20px;
    border-radius: 50px;
    text-decoration: none;
    transition: 250ms all ease-in-out;
  }

  &__highlight-blue:hover {
    background-color: #237b9f;
    color: #ffffff;
  }
}

.back {
  background-color: #237b9f;
  color: #fff;
  padding: 6px 10px;

  i {
    margin-right: 10px;
  }
}

.multi-selection {
  border-radius: 0;
  background-color: $freelancer-light;
}

.round-btn {
  background-color: $freelancer-light;
  color: #000;
  padding: 10px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 10px;
  cursor: pointer;
  transition: all ease-in-out 0.1s;
  z-index: 10;

  &.absolute {
    position: absolute;
  }

  &.jobtype {
    right: -20px;
    top: 32px;
  }

  &.education,
  &.experience {
    right: -20px;
    margin-top: -45px;
  }

  &:hover {
    font-weight: bold;
  }
}

.btn {
  &-google-small {
    width: 32px;
    height: 32px;
    background-color: #4285F4;
    border-radius: 100%;
    border: none;
  }
  &-google {
    max-width: 360px;
    border-radius: 20px;
    display: flex;
    border: 1px solid #dadce0;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 4px 13px;

    @media (min-width: 1024px) {
      width: 100%;
      max-width: 300px;
    }

    & > .block {
      &-label {
        font-family: Roboto, "Nunito Sans", sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0.25px;
        text-align: center;
        display: block;
        flex: 1 1 0;
      }
      &-icon {
        display: block;
        flex: 0 0 20px;
        font-size: 20px;
      }
    }
  }

  &__add-more-v2 {
      background-color: transparent;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-weight: bold;
      font-size: 14px;
      border: none;
      margin-top: 12px;
      color: #929292;
      transition: color 220ms ease-out;
      width: 100%;
      justify-content: flex-end;
    
      &:hover {
        color: #000;
      }
    
      &>*:nth-child(1) {
        width: 7px;
        height: 10px;
        flex: 0 0 16px;
        border-radius: 20px;
        color: white;
        font-size: 7.5px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    
      &>*+* {
        margin-left: 8px;
        flex: 0 0 auto;
      }
    }

  &__add-more-field {
    background-color: transparent;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: bold;
    font-size: 14px;
    border: none;
    margin-top: 15px;
    color: #929292;
    transition: color 220ms ease-out;

    &:hover {
      color: #000;
    }

    & > *:nth-child(1) {
      width: 16px;
      height: 16px;
      flex: 0 0 16px;
      background-color: $agency;
      border-radius: 20px;
      color: white;
      font-size: 7.5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & > * + * {
      margin-left: 8px;
      flex: 0 0 auto;
    }
  }
}

.btn-go-to {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: none;
  background: unset;
  & > * {
    flex: 0 0 auto;
    margin-left: 9px;
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 800;
    line-height: 19px;
    letter-spacing: 0.05em;
    text-align: left;

    &:first-child {
      color: $agency;
      margin-left: 0;
      flex: 0 0 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ffde66;
      height: 28px;
      max-width: 28px;
      border-radius: 50%;
      letter-spacing: 0em;
      text-align: center;
      font-size: 14px;
    }
  }
}

.btn.btn-v2,
.form__btn.btn-v2,
.btn.form__btn.btn-v2 {
  border-radius: .5rem;
}