.app-btn {
  padding: 14px 20px;
  border-radius: var(--radius-lg);
  border: none;
  font-weight: bold;
  font-size: 14px;
  transition: transform var(--t-duration);
  transform: scale(1);
  text-align: center;

  @include mq(lg) {
    font-size: 16px;
  }

  &--text-xs {
    font-weight: 800;
    letter-spacing: 0.05em;
    @include mq(lg) {
      font-size: 12px;
      line-height: 14px;
      padding: 12px 25px;
    }
  }

  &--text-normal {
    font-size: 14px;
    font-weight: 800;
    letter-spacing: 0.05em;
    @include mq(lg) {
      line-height: 15px;
    }
  }

  &--text-md {
    font-size: 14px;
    font-weight: 700;
    line-height: 10px;
    letter-spacing: 0.1px;
    padding: 22px 22px 21px;

    @include mq(lg) {
      font-size: 16px;
      line-height: 18px;
      padding: 16px 29px;
    }

    &.thin {
      padding: 11px 16px;
      font-size: 14px;
      letter-spacing: 0.1px;
      line-height: 10px;
    }
  }

  &--text-short {
    font-size: 14px;
    font-weight: 700;
    line-height: 10px;
    letter-spacing: 0.1px;
    padding: 22px 15px 21px;

    @include mq(lg) {
      font-size: 16px;
      padding: 24px 16px 26px;
    }
  }

  &--bulky {
    padding: 22px 22px 21px;
    line-height: 10px;

    @include mq(lg) {
      padding: 21px 29px 20px;
      line-height: 18px;
    }
  }

  &--text-2line {
    @include mq(lg) {
      padding: 9px 25px;
      line-height: 16px;
    }
  }

  &--dashboard {
    text-transform: capitalize;
    line-height: 1.072em;
    padding: 11px 25px;
    font-size: 14px;
    font-weight: 700;
  }

  &:disabled {
    opacity: 0.5;
  }

  &:hover {
    transform: scale(1.05);
    color: ar(--btn-text-color);
  }

  &-primary {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: var(--btn-text-color);
    background-image: var(--btn-bg);
    background-repeat: no-repeat;
    background-color: var(--third-gradient-color);
    background-position: center;
    background-size: 102% 102%;

    &.app-btn-xl {
      background-image: var(--btn-bg-xl);
    }
    &.app-btn-xxl {
      background-image: var(--btn-bg-xxl);
    }

    &.app-btn-min-radii {
      border-radius: 8px;
    }
    &.app-btn-xs-radii {
      border-radius: 6px;
    }
  }


  &.app-btn-sm-radii {
    border-radius: 9px;
  }

  &-secondary {
    background-image: var(--btn-bg-2);
    background-size: cover;
    background-position: center;
  }

  &-deals {
    width: 102px;
    display: inline-flex;

    @include mq(lg) {
      width: 119px;
    }

    & > img {
      width: 100%;
    }
  }

  &-transparent {
    background-image: none;
    background-color: transparent;
  }

  &-outline {
    background-image: none;
    background-color: transparent;
    color: white;
    border: 1px solid white;
  }

  &-monochrome {
    background-image: none;
    background-color: var(--btn-monochrome-bg);
    color: var(--btn-monochrome-color);
  }

  &-black {
    background-image: none;
    background-color: black;
    color: white;
  }

  &-rounded {
    border-radius: 999px;
    padding: 7px;
  }

  .text-capitalize {
    text-transform: capitalize;
  }
  .text-uppercase {
    text-transform: uppercase;
  }

  &-smaller {
    font-size: 12px;
    font-weight: 800;
    line-height: 8px;
    letter-spacing: 0.05em;
    text-align: center;
    border-radius: 6px;
    padding: 16px 13px 14px;

    @include mq(lg) {
      font-size: 12px;
      font-weight: 800;
      line-height: 8px;
      letter-spacing: 0.05em;
    }

    &.w-700 {
      font-weight: 700;
    }
  }

  &-trigger-profile {
    --avatar-size: 61px;

    @include mq(lg) {
      --avatar-size: 48px;
    }
    width: var(--avatar-size);
    height: var(--avatar-size);
    overflow: hidden;
    padding: 0;
    margin-right: 10px;

    @include mq(lg) {
      margin-left: 10px;
      margin-right: 0;
    }
    @include mq(xl) {
      margin-left: 23px;
      margin-right: 0;
    }

    & > img {
      width: 100%;
      height: 100%;
      background-color: var(--btn-bg);
      object-fit: cover;
    }
  }

  &-variant-link {
    font-size: 14px;
    font-weight: 500;
    line-height: 10px;
    text-transform: capitalize;
    text-decoration: underline;
    padding: 0;
  }

  &-error {
    background-color: var(--error-primary);
    color: white;
    background-image: none;
  }
}

.app-btn-modal-close {
  position: absolute;
  right: 8px;
  top: 8px;
  font-size: 8px;
  z-index: 4;

  &.no-sticky {
    position: unset;
  }

  &.text-upsize {
    font-size: 15px;
  }
}

.theme a.app-btn,
.theme a.app-badge {
  &:hover {
    color: var(--btn-text-color);
  }
}

.app-link {
  &-minimalism {
    text-decoration: underline;
    font-weight: 500;
    line-height: 20.23px;
    font-size: 14px;

    @include mq(lg) {
      font-size: 14px;
      line-height: 20.23px;
    }
  }
}
