.rsw-editor,
.rdw-editor-wrapper {
  background-color: var(--input-bg-color);
  color: var(--input-text-color);
  border: 1px solid var(--input-bg-color);
  border-radius: var(--radius-sm);
  // padding: 5px 18px;
  font-family: var(--font-body);
  font-size: 16px;
  // font-weight: 700;
  line-height: 23.12px;
  letter-spacing: 0.1px;

  // .rsw-ce {
  //   padding: 5px 18px;
  // }
}

.field-wide {
  .rsw-edito,
  .rdw-editor-wrapper {
    height: 230px;
  }

  &.error-state {
    .rsw-editor,
    .rdw-editor-wrapper {
      border-color: var(--error-primary);
      border-width: 5px;
      color: var(--error-primary);
      background-color: var(--error-accent);
    }
  }
}
