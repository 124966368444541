.pagination-bubble {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

    @media only screen and (min-width: 992px) {
        margin-bottom: 30px;
    }

    &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        color: #ffffff;
        margin-right: 20px;
        font-weight: 500;
    }

    &__item:last-of-type {
        margin-right: 0;
    }

    &__active {
        background-color: #e9f2f5;
        color: #237b9f;

        &-agency {
            background-color: $agency-mid;
            color: $agency;
        }
    }

    &__completed {
        border: 1px solid #237b9f;
        color: #237b9f;
        transition: 200ms all ease-in-out;
    }

    &__completed:hover {
        transform: scale(1.1);
        cursor: pointer;
    }
    // for freelancer
    &__freelancer {
        &__item {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            color: #8c79b5;
            margin-right: 20px;
            font-weight: 500;
        }

        &__item:last-of-type {
            margin-right: 0;
        }

        &__active {
            background-color: #8c79b5;
            color: #fff;
        }

        &__completed {
            border: 1px solid #8c79b5;
            color: #8c79b5;
            transition: 200ms all ease-in-out;
        }

        &__completed:hover {
            transform: scale(1.1);
            cursor: pointer;
        }
    }
}
