.credits-calculator {
    background-color: #F0F6F8;
    padding: 30px;
    margin-bottom: 40px;

    &__heading {
        font-size: 30px;
        font-weight: 700;
        margin-bottom: 20px;
    }

    &__input {
        border: 0px;
        font-size: 30px;
        font-weight: 300;
        width: 50%;
        height: 65px;
        padding: 10px 20px;
        margin-bottom: 20px;
        outline: none;
        border-bottom: 0px solid #237B9F;
        transition: 100ms all ease-in-out;
    }

    &__input:focus {
        border-bottom: 5px solid #237B9F;
    }

    &__subheading {
        font-size: 14px;
        font-weight: 500;
    }

    &__body {
        font-size: 16px;
        font-weight: 300;
        margin-bottom: 20px;

        &__italic {
            font-size: 14px;
            font-style: italic;
        }
    }

    &__total {
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: 20px;
    }

    &__add-to-cart {
        width: 100%;
        transition: 200ms all ease-in-out;

        &__btn {
        border: 2px solid #237B9F;
        color: #237B9F;
        font-size: 18px;
        font-weight: 700;
        padding: 7px 60px;
        text-align: center;
        }

        &__btn:hover {
            transform: scale(1.05);
            text-decoration: none;

        }
    }

    &__add-to-cart:hover {
        transform: scale(1.05);
    }

}
