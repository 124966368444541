.mobile-menu {
    background-color: #51524f;
    width: 100%;
    height: 100vh;
    padding: 25px 15px;
    position: fixed;
    top: 0;
    right: -100%;
    z-index: 999;
    transition: all 250ms ease-in-out;
    color: #ffffff;

    &__inner {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-top: 70px;

        &__close {
            display: flex;
            justify-content: flex-end;
        }

        &__social {
            text-align: right;
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        list-style-type: none;
        margin-bottom: 60px;
        padding-inline-start: 0px;

        &__item-large {
            margin-top: 20px;
            text-align: right;
            font-size: 18px;
            text-transform: uppercase;
        }

        &__item-small {
            font-size: 16px;
            text-align: right;
        }
    }

    @media only screen and (min-width: 992px) {
        display: none;
    }

    &.active {
        right: 0px;
    }

    &.agency {
        background-color: $agency;
    }

    &.company {
        background-color: $company;
    }

    &.freelancer {
        background-color: $freelancer;
    }

    &.agency-v2 {
        &.active {
            background-color: $agency;
        }
    }
}
