#hamburger {
  width: 35px;
  height: 24px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 500ms ease-in-out;
  -moz-transition: 500ms ease-in-out;
  -o-transition: 500ms ease-in-out;
  transition: 500ms ease-in-out;
  cursor: pointer;
  z-index: 1001;
}

#hamburger span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #ffffff;
  border-radius: 3px;
  opacity: 1;
  right: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 200ms ease-in-out;
  -moz-transition: 200ms ease-in-out;
  -o-transition: 200ms ease-in-out;
  transition: 200ms ease-in-out;
  z-index: 2;
}

#hamburger span:nth-child(1) {
  top: 0px;
  -webkit-transform-origin: right center;
  -moz-transform-origin: right center;
  -o-transform-origin: right center;
  transform-origin: right center;
}

#hamburger span:nth-child(2) {
  top: 9px;
  -webkit-transform-origin: right center;
  -moz-transform-origin: right center;
  -o-transform-origin: right center;
  transform-origin: right center;
}

#hamburger span:nth-child(3) {
  top: 18px;
  -webkit-transform-origin: right center;
  -moz-transform-origin: right center;
  -o-transform-origin: right center;
  transform-origin: right center;
}

#hamburger.open span:nth-child(1) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: -3px;
  left: 0px;
}

#hamburger.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

#hamburger.open span:nth-child(3) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 22px;
  left: 0px;
}

.humberger-bg {
  background-color: $agency-accent;
  position: absolute;
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  top: -6px;
  left: -4px;
  border-radius: 3px;
  z-index: 1;
}

.hamburger-black {
    background-color: #0E0E0E;
    position: absolute;
    width: 61px;
    height: 61px;
    top: 0;
    border-radius: 14px;
    z-index: 1;
    &__white-lines {
      background-color: white;
    }
}

#hamburger.with-contrast-bg {
  height: 46px;
  width: 40px;
  & > span {
    background: $agency;
    height: 4px;
    width: 21px;
    right: 10px;
    border-radius: 0;

    &:nth-child(1){
      top: 12px;
    }
    &:nth-child(2){
      top: 19px;
    }
    &:nth-child(3){
      top: 26px;
    }
  }

  &.open {
    span:nth-child(1),
    span:nth-child(3) {
      left: 8px;
    }
    span:nth-child(1){
      top: 11px;
    }
  }
}
