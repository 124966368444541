.display-background {
  width: var(--full);
  height: var(--full);
  position: absolute;
  z-index: 1;
  overflow: hidden;
}

#gradient-background {
  width: 100%;
  height: 100%;
  --gradient-color-1: var(--first-gradient-color);
  --gradient-color-2: var(--second-gradient-color);
  --gradient-color-3: var(--third-gradient-color);
  --gradient-color-4: var(--fourth-gradient-color);
}


.display-bg-gradient {
  background: radial-gradient(
        var(--second-gradient-color) 0,
        var(--second-gradient-color-transparent) 60% 100%
      ) -940px 290px,
    radial-gradient(
        var(--third-gradient-color) 10%,
        var(--third-gradient-color-transparent) 60% 100%
      ) -120px 250px,
    radial-gradient(
        var(--second-gradient-color) 40%,
        var(--second-gradient-color-transparent) 57% 100%
      )
      495px -44px,
    radial-gradient(
        var(--first-gradient-color) 30%,
        var(--first-gradient-color-transparent) 67% 100%
      )
      122px -120px,
    radial-gradient(
        var(--first-gradient-color) 10%,
        var(--first-gradient-color-transparent) 60% 100%
      ) -420px 120px,
    radial-gradient(
        var(--third-gradient-color) 15%,
        var(--third-gradient-color-transparent) 50% 100%
      ) -620px 0,
    radial-gradient(
        var(--third-gradient-color) 25%,
        var(--third-gradient-color-transparent) 50% 100%
      )
      520px -250px,
    var(--first-gradient-color);
  }