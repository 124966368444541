.table {
    &__simple {
        td,
        th {
            border-top: none;
        }
        td {
            border-bottom: 1px solid #eaeaea!important;
        }
        th {
            border-bottom: 1px solid #000000!important;
        }
    }
}
