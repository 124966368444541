.card__small {
  .txt__fs-18 {
    font-size: 16px;

    @media (max-width: 1024px) {
      font-size: 14px;
    }
  }

  .link-freelancer {
    font-size: 14px;

    @media (max-width: 1024px) {
      font-size: 13px;
    }
  }
}
