.range-slider {
    width: 100%;
    margin-top: 20px;

    &__freelancer {

        &__selector {
            width: 26px;
            height: 26px;
            background-color: $freelancer;
            border-radius: 50%;
            position: absolute;
            z-index: 4;
            display: inline-block;
            cursor: pointer;
        }

        &__selected-range {
            background-color: $freelancer-mid;
            z-index: 3;
            position: relative;
            width: 24%;
            top: 7px;
            left: 51%;
            height: 10px;
            cursor: pointer;
        }

        &__full-range {
            background-color: $freelancer-light;
            width: 100%;
            z-index: 2;
            position: relative;
            height: 10px;
            top: -3px;
            left: 0;
            cursor: pointer;
        }
    }

    &__labels {
        margin-top: 15px;
        display: flex;
        flex-grow: 1;
        flex-direction: row;
        font-size: 16px;

        &__min {
          margin-right: 5px;
        }

        &__max {
          margin-left: 5px;
        }
    }
}

.selector-low {
    left: 50%;
}

.selector-high {
    right: 25%;
}
