.deal {
    display: flex;
    justify-content: space-between;
    width: 100%;
    &:hover {
        a {
            color: var(--second-gradient-color);
        }
    }
    @include mq(lg) { 
    align-items: center;
        border-bottom: 1px solid white;
        padding-inline: 21px;
        padding-block: 15px;
    }
    .company-info {
        display: flex;
        align-items: center;
        gap: 14px;
        @include mq(lg) { 
            gap: 37px;
        }
    }
    &--header {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding-block: 10px;
    }
    .divider {
        border-left: 1px solid #929292;
        border-right: none;
        border-top: none;
        border-bottom: none;
        height: 100%;
        width: 1px;
        @include mq(lg) {
            display: none;
        }
    }
    .company-logo {
        width: 100px;
        height: 100px;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        @include mq(lg) { 
            width: 60px;
            height: 60px;
        }
    }
    a {
        text-decoration: underline;
        font-weight: 500;
        font-size: 14px;
    }
    h3 {
        font-size: 16px;
        line-height: 23.12px;
        margin-bottom: 0;
    }
    .hide-on-mobile {
        display: none;
        @include mq(lg) { 
            display: block;
        }
    }
    .hide-on-desktop {
        display: block;
        @include mq(lg) {
            display: none;
        }
    }
}

.deals-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 40px;
    @include mq(lg) {
        gap: 0;
        border-top: 1px solid white;
    }
}