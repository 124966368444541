.teammember {
    border: 1px solid white;
    border-radius: 12px;
    padding-block: 17px;
    max-width: 585px;
    padding-inline: 18px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 16px;
    &_name {
        font-size: 16px;
        font-weight: 700;
    }
    &_email {
        font-size: 16px;
        font-weight: 400;
    }
    &_button-cont {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    &_send {
        background-image: var(--btn-bg-xxl);
        height: 54px;
        width: 100%;
        border: none;
        font-size: 16px;
        font-weight: 700;
        border-radius: 9px;
        color: white;
        padding-inline: 18px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        line-height: 16px;
    }
    &_deletebutton {
        background: #FF2A2A;
        border-radius: 9px;
        color: white;
        font-size: 16px;
        font-weight: 700;
        border: none;
        padding-inline: 18px;
        height: 54px;
        width: 100%;
    }
    @include mq(lg) {
        align-items: center;
        flex-direction: row;
        padding-block: 5px;
        padding-left: 20px;
        padding-right: 5px;

        &_button-cont {
            display: flex;
            flex-direction: row;
            gap: 4px;
        }
    }
}

.team-cont {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 18px;
}

.team-heading {
    font-size: 22px;
    font-weight: 700;
    color: white;
    margin-top: 40px;
    @include mq(lg) { 
        font-size: 24px;
    }
}

.invite-cont {
    max-width: 439px;
}