.sidenav {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 99; /* Stay on top */
  top: 0;
  right: 0;
  background-color: var(--primary-color); /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding: 108px 15px 50px;
  transition: 700ms ease-out; /* 0.5 second transition effect to slide in the sidenav */
  width: 100%;
  display: flex;
  justify-content: flex-end;
  text-align: right;
  transform: translateX(100%);

  @include mq(lg) {
    width: 480px;
  }

  &.open {
    transform: translateX(0%);
    @include mq(lg) {
      transform: translateX(100%);
    }
  }
}

.modal-open {
  overflow: hidden;
}

.sidenav-menu {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 4rem;

  &__links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    gap: 1rem;
  }

  &--item {
    padding: 12px 0;
    font-family: var(--font-heading);
    font-size: 1.6rem;

    &.active {
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .footer-social {
    margin-top: auto;
    justify-content: center;

    &--item {
      color: var(--primary-color);

      & + .footer-social--item {
        margin-left: 1.8rem;
      }
    }
  }
}
