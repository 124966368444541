.black-card {
    background: #0E0E0E;
    border-radius: 25px;
    color: #FFFFFF;
    padding: 62px 20px;
    &__full {
    padding: 32px 20px;
    }
    &__heading {
        font-size: 32px;
        text-align: left;
        @media only screen and (min-width: 992px) {
            font-size: 40px;
        }
    }

    @media only screen and (min-width: 992px) {
        max-width: 525px;
        margin: auto;
        padding: 75px 43px;
        border-radius: 30px;
        &__full {
            max-width: 100%;
            margin: auto;
            padding: 65px 58px;
            border-radius: 30px;
        }
    }
}