@use 'sass:map';

@mixin mq($mq-breakpoint, $mq-breakpoints: $breakpoints) {
    // If $mq-breakpoint is a key that exists in
    // $mq-breakpoints, get and use the value
  @if map-has-key($mq-breakpoints, $mq-breakpoint) {
    $mq-breakpoint: map-get($mq-breakpoints, $mq-breakpoint);
  }
  
  @media screen and (min-width: #{$mq-breakpoint}) {
    @content;
  }
}

@mixin mqup($mq-breakpoint, $mq-breakpoints: $breakpointsUp) {
    // If $mq-breakpoint is a key that exists in
    // $mq-breakpoints, get and use the value
  @if map-has-key($mq-breakpoints, $mq-breakpoint) {
    $mq-breakpoint: map-get($mq-breakpoints, $mq-breakpoint);
  }
  
  @media screen and (max-width: #{$mq-breakpoint}) {
    @content;
  }
}
  
  @mixin addcssvariables($prefix, $suffix, $names) {
    @each $property, $keys in $names {
        @if $property == 'default' {
            @each $a, $b in $keys {
                #{$prefix}#{$a}#{$suffix}: #{$b};
            }
        } @else {
            &.#{$property} {
                @each $a, $b in $keys {
                    #{$prefix}#{$a}#{$suffix}: #{$b};
                }
            }
        }
        
    }
  }
  