.app-footer {
  display: flex;
  justify-content: center;
  background-color: var(--primary-color);
  font-size: 14px;
  letter-spacing: -0.05em;
  line-height: 1.43em;

  .footer-container {
    flex: 0 0 100%;
    padding: 51px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include mq(lg) {
      padding: 51px 80px;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
    }

    @include mq(xl) {
      flex-direction: row;
      justify-content: center;
    }

    .footer--item {
      margin-bottom: 50px;
      @include mq(lg) {
        margin-bottom: 0;
      }
      & + .footer--item {
        @include mq(lg) {
          margin-left: 7%;
        }
      }
    }
  }
}

.footer-logo {
  height: 46px;
  width: 123px;
  margin-bottom: 23px;

  & > img {
    max-width: 100%;
    height: 100%;
  }
}

.footer-copy {
  margin-bottom: 1px;
  &.last {
    margin-bottom: 17px;
  }
}

.footer-list-menu {
  display: flex;
  flex-direction: column;

  &--heading {
    font-size: 20px;
    line-height: 29px;
    letter-spacing: 0;
    font-weight: 700;
    margin-bottom: 10px;
  }

  &--item {
    font-size: 16px;
    margin-bottom: 1px;
    letter-spacing: -0.05em;
    line-height: 1.43em;
  }
}

.footer-social {
  display: flex;
  margin-top: 17px;

  &.end {
    justify-content: flex-end;
  }

  &--item {
    display: flex;
    flex: 0 0 30px;
    width: 30px;
    height: 30px;
    background-color: #fff;
    color: #50524e;
    align-items: center;
    justify-content: center;
    border-radius: 50px;

    &:hover {
      color: initial;
    }

    & + .footer-social--item {
      margin-left: 10px;
    }
  }
}
