.form-v2 {
  &__edit-profile {
    width: 80%;
    margin: 0 !important;
    margin-top: 20px !important;

    @media only screen and (max-width: 768px) {
      margin: auto !important;
      margin-top: 20px !important;
    }
  }

  &__edit-profile-textarea {
    max-width: 100% !important;
  }

  &__error {
    color: #f05454;
    border: 3px solid #ff2a2a !important;
    width: 100%;
    border-radius: 12px;
    &::placeholder {
      color: #f05454 !important;
    }
  }

  &__input-block {
    margin-bottom: 25px;

    &.extra-b-space {
      margin-bottom: 50px;
    }
  }

  &__input-wrapper {
    width: 100%;
  }

  &__input-group {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;

    &__input {
      width: 1%;
      height: 39px;
      padding: 10px;
      outline: none;
      font-size: 16px;
      box-sizing: border-box;
      transition: 150ms all ease-in-out;
      font-weight: 300;
      position: relative;
      -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      margin-bottom: 0;
      font-weight: 700;
    }

    &-append {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      border-bottom: 1px solid black;
    }
  }

  &__label {
    padding-left: 10px;
    text-align: left;
    pointer-events: none;
    line-height: 18px;
    color: #ffffff;
    font-size: 14px;
    margin-bottom: 13px;
    font-weight: 400;
    &-error {
      color: #ff2a2a !important;
    }
  }

  &__grey {
    color: #ababab;
  }

  &__input {
    width: 100%;
    height: 43px;
    padding: 10px;
    padding-top: 12px;
    outline: none;
    font-size: 16px;
    box-sizing: border-box;
    transition: 150ms all ease-in-out;
    font-weight: 700;
    border-radius: 12px;
    border: none;

    &__search {
      width: 100%;
      border: none;
      border-bottom: 1px solid #000000;
      outline: none;
      padding: 10px;
      transition: all 200ms ease-in-out;
    }

    &__search:focus::placeholder {
      opacity: 0;
    }

    &__search::placeholder {
      color: #ababab;
      transition: all 100ms ease-in-out;
    }

    &__border-light {
      @extend .form__input;
      border: 1px solid #ababab;
    }
  }

  &__underline {
    border-bottom: 3px solid $company;
  }

  &-agency {
    .form__underline {
      border-bottom: none;
    }

    .field__input-alt {
      &:focus-visible {
        outline-color: $agency;
      }

      &.control-error {
        border-color: #ff0000;
      }
    }

    &.form-about {
      .form__input-block {
        padding-top: 10px;
      }
    }
  }

  &__clear {
    text-align: right;
    margin-top: -28px;
    color: #cecece;
    padding-right: 5px;
    cursor: pointer;
    opacity: 1;
    transition: 150ms all ease-in-out;
  }

  &__clear:hover {
    color: #ababab;
  }

  &__hidden {
    opacity: 0;
    pointer-events: none;
  }

  &__btn,
  &__btn-outline,
  &__btn-outline-purple {
    padding: 18px;
    font-weight: 700;
    text-transform: capitalize;
    letter-spacing: 1px;
    cursor: pointer;
    transition: 200ms all ease-in-out;
    width: 100%;
    border-radius: 0;

    @media only screen and (min-width: 992px) {
      margin: auto;
    }
  }

  &__btn:hover,
  &__btn-outline:hover,
  &__btn-outline-purple:hover {
    transform: scale(1.05);
  }

  &__btn-small {
    padding: 12px;
    font-weight: 700;
    text-transform: capitalize;
    letter-spacing: 1px;
    cursor: pointer;
    transition: 200ms all ease-in-out;
    width: 100%;
    max-width: 180px;
    border-radius: 5px;
    background-color: $company;
    color: #ffffff;
    margin-top: 50px;
    text-align: center;

    &:hover {
      color: #fff;
    }
  }

  &__btn {
    background-color: $company;
    color: #ffffff;
    margin-top: 40px;
    text-align: center;

    &:hover {
      color: #fff;
    }

    &-agency-v2 {
      border-radius: 12px;
      margin-bottom: 26px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      font-size: 14px;
      @media (min-width: 768px) {
        font-size: 16px;
      }
    }

    &.btn-wider {
      padding-left: 40px;
      padding-right: 40px;

      @media (min-width: 768px) {
        padding-left: 58px;
        padding-right: 58px;
        width: 300px;
      }
    }
  }

  &__btn-outline {
    border: 1px solid $company;
    color: $company;

    &:hover {
      color: $company;
    }
  }

  &__btn-outline-purple {
    border: 1px solid #8c79b5;
    color: #8c79b5;
    text-align: center;

    &:hover {
      color: #8c79b5;
    }
  }

  &__btn-marketing {
    @extend .form__btn;
    color: #ffdf66;
    background: #eb605d;
    text-align: center;
  }

  &__text {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &__toggle-password {
    width: 20px;
    right: 16px;
    color: #cecece;
    text-align: center;
    top: -28px;
    position: relative;
    float: right;
    cursor: pointer;
    transition: 200ms all ease-in-out;
  }

  &__toggle-password:hover {
    color: #ababab;
  }

  &__heading {
    font-weight: 700;
    font-size: 14px;
    text-align: left;
    margin-top: 40px;
    margin-bottom: 5px;
  }

  &__textarea {
    height: 160px;
    width: 100%;
    max-width: 500px;
    font-weight: 700;
    padding: 10px;
    box-sizing: border-box;
    outline: none;
    resize: none;
    transition: 200ms all ease-in-out;
    border-radius: 12px;

    &__character-limit {
      width: 100%;
      max-width: 500px;
      text-align: right;
      font-size: 12px;
    }

    &__border-light {
      @extend .form__textarea;
      border: 1px solid #ababab;
    }
  }

  &__textarea:focus {
    border-bottom: 5px solid $company;
  }

  &__two-cols {
    width: 100%;

    &__inner {
      width: 100%;
      float: none;

      @media only screen and (min-width: 992px) {
        width: 47%;
        float: left;
        margin-right: 6%;
      }
    }

    &__inner:nth-of-type(2) {
      @media only screen and (min-width: 992px) {
        margin-right: 0;
      }
    }
  }

  &__country {
    padding-bottom: 0 !important;
    padding-top: 0;
  }

  &__two-cols-constant {
    width: 100%;

    &__inner {
      width: 47%;
      float: left;
      margin-right: 6%;
    }

    &__inner:nth-of-type(2) {
      margin-right: 0;
    }
  }

  &__search {
    width: 100%;
    text-align: right;
    font-size: 22px;
    margin-top: -38px;
    margin-right: 10px;
    margin-bottom: 15px;
    color: #cecece;
    padding-right: 10px;

    &__active {
      color: #8c79b5;
      cursor: pointer;
      transition: 200ms all ease-in-out;
    }

    &__selection {
      display: inline-block;
      margin-right: 30px;

      &-list-view {
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 10px 20px 15px;
        border-bottom: 1px solid #efefef;

        &:first-child {
          padding-top: 0;
        }
      }
    }

    &__remove-selection {
      color: #cecece;
      margin-left: 7px;
      cursor: pointer;
      position: relative;
      top: 1px;
      transition: 150ms all ease-in-out;
    }

    &__remove-selection:hover {
      color: #ababab;
    }
  }

  &__freelancer {
    .form__underline {
      border-bottom: 3px solid $freelancer;
    }

    .form__active {
      color: $freelancer;
    }

    .form__textarea:focus {
      border-bottom: 5px solid $freelancer;
    }
  }

  &__forgetpassword {
    text-align: right;
    position: relative;
    margin-top: 12px;
    & > a {
      color: #ffffff;
      font-weight: 400;
      font-size: 14px;
    }
  }
}

input::-webkit-calendar-picker-indicator {
  display: none;
}

select {
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
}

/* For IE10 */
select::-ms-expand {
  display: none;
}

input[type='radio'] {
  margin-right: 10px;
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid darkgray;
  border-radius: 50%;
  outline: none;
}

input[type='radio']:hover {
  box-shadow: 0 0 5px 0px #ababab inset;
}

input[type='radio']:before {
  content: '';
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}

input[type='radio']:checked:before {
  // background: $company;
}

.searchBox {
  width: 100%;
}

.label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
  height: 20px;

  &__success {
    background-color: #5cb85c;
  }
}

.flex-col {
  flex-direction: column;
}

.field-v2 {
  &__label {
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 0.5rem;
  }

  &__upload {
    cursor: pointer;
    position: relative;

    .images-cont {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .up-container {
      position: relative;
      img {
        object-fit: cover;
        height: unset;
        max-width: 100%;
        border-radius: 12px;
      }
      .upload-hover {
        position: absolute;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background: #000000bf;
        z-index: 1;
      }
      .close-button {
        width: 22px;
        height: 22px;
        background-color: #ffffff;
        border-radius: 100px;
        position: absolute;
        top: 18px;
        right: 18px;
        cursor: pointer;
        color: black;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
      }

      &.square-container {
        padding-top: 100%;
        position: relative;

        & > .square-component {
          position: absolute;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .up-icon-cyrcle {
      width: 86px;
      height: 86px;
      font-size: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      margin-bottom: 12px;
      color: white;
    }

    .up-text i {
      color: #ffffff;
      font-style: normal;
      font-weight: 400;
    }
  }

  &__phone-button {
    border: none !important;
    background: #9a1b3e !important;
    margin: 4px !important;
    border-radius: 9px !important;
    padding-inline: 0 !important;
    width: 70px !important;
    .arrow {
      display: none !important;
    }
    .country:hover {
      background-color: #9a1b3e !important;
      color: white !important;
    }
    .country:hover .dial-code {
      color: white !important;
    }
    .selected-flag {
      background-color: unset !important;
      padding: 0 !important;
      width: 100% !important;
      padding-inline: 18px !important;
      &::after {
        content: '';
        background-image: url('/img/chevron-down.svg') !important;
        background-position: 70% !important;
        background-repeat: no-repeat !important;
        display: block;
        width: 10px;
        height: 10px;
        position: absolute;
        right: 18px;
        top: 40%;
        transition: all 0.3s ease-in-out;
      }
    }
    .selected-flag.open {
      &::after {
        transform: rotate(180deg);
      }
    }
    .selected-flag:hover {
      background-color: unset !important;
    }

    .country-list .country.active {
      background-color: unset;
      color: unset;
    }

    .country-list .country.highlight .dial-code {
      color: white !important;
    }

    .country-list .country.highlight {
      background-color: black !important;
      color: white !important;
    }
  }

  &__input-alt {
    background-color: #f2f2f2;
    width: 100%;
    height: 35px;
    border-radius: 4px !important;
    border: 1px solid #929292;
    padding-right: 5px;
    padding-left: 5px;

    &-primary {
      color: var(--primary-color);

      &.text-bold {
        font-weight: 700;
      }
    }

    &.long-text {
      height: auto;
    }

    &.with-important {
      border: 1px solid #929292 !important;
      background-color: #f2f2f2 !important;
      padding-left: 58px !important;

      & ~ .flag-dropdown .selected-flag {
        width: 50px;
        background-color: white;
        border-width: 1px 0 1px 1px;
        border-color: #929292;
        border-style: solid;
      }
    }
  }

  &__option-text {
    text-align: right;
    font-weight: 500;
    font-size: 14px;
  }
}

.switch {
  position: relative;
  display: block;
  width: 30px;
  height: 16px;
  margin-bottom: 0;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  input:checked + .slider {
    background-color: #9a1b3e;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #9a1b3e;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(12px);
    -ms-transform: translateX(12px);
    transform: translateX(12px);
  }
}

.switch-block {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;

  & > *:first {
    flex: 0 0 30px;
    display: block;
  }

  & > * + * {
    flex: 0 0 auto;
    color: #929292;
    padding-left: 12px;
    font-weight: 500;
    max-width: 90%;
    @media (min-width: 768px) {
      max-width: 100%;
    }

    &:empty {
      display: none;
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #929292;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;

    &:before {
      position: absolute;
      content: '';
      height: 12px;
      width: 12px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
    }
  }

  &.switch-error {
    .slider {
      background-color: #ff0000;
    }
  }
}

.selected-options {
  display: block;
  text-align: left;

  & > h5 {
    font-size: 16px;
    font-weight: bold;
    color: white;
  }

  .switch-area {
    columns: 1;

    @media (min-width: 768px) {
      columns: 1;
    }
  }
}

form {
  .container-tight {
    @media (min-width: 768px) {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
}

.form__select {
  background-color: var(--input-bg-coolor, #f2f2f2);
  border: 1px solid var(--primary-color, #929292);
  color: var(--primary-color, #929292);
  font-weight: 700;
  font-size: 14px;
  padding: 8px 10px;
  width: 100%;
  border-radius: 4px;
  position: relative;

  &:focus-visible {
    outline: unset;
  }
}

.select__wrapper {
  position: relative;

  .select_chevron {
    right: 10px;
    top: 8px;
    width: 14px;
    height: 14px;
    position: absolute;
    font-size: 14px;
    color: var(--primary-color);
  }
}

.switch {
  &-xs {
    font-size: 14px;
  }
}
