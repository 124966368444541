$agency: #71bfb2;
$agency-mid: #d9f9f4;
$agency-light: #f0f6f8;
$agency-accent: #ffde66;
$freelancer: #8c79b5;
$freelancer-mid: #c0b5d6;
$freelancer-light: #f4f2f8;
$marketing: #eb605d;
$marketing-mid: #f5afae;
$marketing-light: #f9cfce;
$red: #ff0000;
$body: #000;

$company: #237b9f; /* TODO: Current agency should change to this */
$company-mid: #91bdcf;
$company-light: #f0f6f8;
