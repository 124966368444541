.gridcards-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 24px;

  margin-left: -12px;
  margin-right: -12px;

  @include mq(lg) {
    flex-wrap: wrap;
    margin-left: -11px;
    margin-right: -11px;
    margin-top: 40px;
  }

  &--item {
    flex: 0 0 50%;
    padding-left: 12px;
    padding-right: 12px;
    margin-bottom: 24px;
    @include mq(lg) {
      flex: 0 0 25%;
      padding-left: 11px;
      padding-right: 11px;
      margin-bottom: 0;
    }

    &.with-bottom-space {
      margin-bottom: 60px;
    }
    &.full-width-mobile {
      flex: 0 0 100%;
      @include mq(lg) {
        flex: 0 0 25%;
      }
    }
  }

  .grid-item--image {
    border-radius: 0;
    @include mq(lg) {
      border-radius: 8px;
    }

    &.light-radius {
      border-radius: 0;
      @include mq(lg) {
        border-radius: 1px;
      }
    }
  }
}

.grid-item {
  &--image {
    padding-top: 80.32%;
    overflow: hidden;
    position: relative;
    border-radius: var(--radius-xs);
    display: block;

    @include mq(lg) {
      border-radius: var(--radius-xl);
    }

    &-casestudy {
      border-radius: 1px;
    }

    & > img {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &--divider {
    height: 1px;
    border-top: 1px solid #fff;
    margin-top: 14px;
    margin-bottom: 14px;
  }

  &--hedline {
    font-size: 14px;
    font-weight: normal;
    line-height: 10px;
    display: inline-block;
    & > * {
      display: block;
    }

    .h-name {
      font-size: 14px;
      font-weight: 700;
      line-height: 10px; 

      & ~ span {
        margin-top: 8px;
      }
    }

    span {
      font-size: 14px;
      font-weight: 400;
      line-height: 10px;
      line-height: 20.23px;
    }
  }
  &--title {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-transform: capitalize;
  }
}
