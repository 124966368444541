.app-modal {
  &--mask {
    // background-color: rgba(0, 0, 0, 0.5);
    @extend .display-bg-gradient;
    opacity: 0.9;
    transition: background var(--t-duration, 400ms);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    z-index: 1000;
  }

  &--container {
    --px: 25px;
    --pt: 25px;
    --py: 60px;
    --mx: 40px;
    @media screen and (min-width: 1024px) {
      .panel-back {
        --px: 43px;
        --pt: 30px;
        --py: 65px;
        --mx: 50px;
        --max-width: 1180px;
      }
      top: 10%;
    }
    margin: auto;
    position: fixed;
    z-index: 1001;
    left: 0;
    top: 5%;
    right: 0;
    // bottom: 0;
    max-width: 90vw;
    max-height: 90vh;
    background-color: var(--primary-color);
    border-radius: 26px;
    padding-left: var(--px);
    padding-right: var(--px);
    padding-top: var(--pt);
    padding-bottom: var(--py);
    margin-bottom: var(--mx);
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      margin-block: 20px;
    }

    &::-webkit-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      background-color: var(--tabler-border-color);
      border-radius: 20px;
      -webkit-border-radius: 20px;
    }

    @include mq(lg) {
      max-width: 550px;
    }

    &--header {
      margin-left: -5px;
      width: 105%;
      height: 55px;
      position: sticky;
      top: -25px;
      background-color: var(--primary-color);
      z-index: 999;

      .app-btn-modal-close {
        top: 0;
        right: 0;
        position: sticky;
        margin-left: auto;
        display: flex;
      }
    }
  }
}
