.add-opportunity-row {
	margin-top: 30px;

	&__title {
		font-size: 16px;
		font-weight: 500;
		margin-bottom: 5px;
	}

	&__content {
		font-size: 16px;
		font-weight: 300;
	}

	&__edit {
		font-size: 14px;
		font-weight: 500;
		color: #237B9F;
		text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        @media only screen and (min-width: 992px) {
            justify-content: flex-end;
        }
	}

	&__edit:hover {
		text-decoration: underline;
		color: #237B9F;
	}

	&__toggle {
		display: flex;
		justify-content: flex-end;
	}
}

.add-opportunity-row:first-of-type {

    @media only screen and (min-width: 576px) {
        margin-top: 0px;
    }
}
