.credits-cart {
    width: 100%;
    margin-bottom: 100px;

    @media only screen and (min-width: 992px) {
        padding-left: 35px;
    }

    &__promotion {
        margin-bottom: 30px;

        &__heading {
            font-size: 16px;
            margin-bottom: 5px;
        }

        &__input-area {
            display: flex;
            border: 1px solid #CECECE;
            height: 44px;
            transition: 100ms all ease-in-out;
        }

        &__input {
            width: 80%;
            padding: 10px;
            outline: none;
            border: none;
            font-size: 16px;
            font-weight: 300;
        }

        &__apply {
            width: 20%;
        }

        &__apply:focus {
            outline: none;
        }

        &__apply button {
            color: #237B9F;
            background: none;
            font-weight: 500;
            border: none;
            outline: none;
            width: 100%;
            height: 100%;
            transition: 150ms all ease-in-out;
        }

        &__apply button:hover {
            transform: scale(1.1);
        }

        &__underline {
            border-bottom: 3px solid #237B9F;
        }

    }

    &__cost-breakdown {
        display: flex;
        border-bottom: 1px solid #CECECE;
        padding-bottom: 10px;
        margin-bottom: 10px;
        font-size: 14px;

        &__heading {
            width: 50%;
            text-transform: uppercase;
        }

        &__content {
            width: 50%;
            text-align: right;
        }

    }

    &__cost-breakdown:last-of-kind {
        border-bottom: none;
        background: red;
    }

    &__promo-discount {
        display: flex;
        margin-top: 5px;
        margin-bottom: 10px;

        &__heading {
            width: 50%;
            font-size: 12px;
        }

        &__content {
            width: 50%;
            font-size: 14px;
            text-align: right;
        }
    }

    &__total {
        display: flex;
        font-weight: 700;
        text-transform: uppercase;
        padding-top: 10px;
        border-top: 1px solid #000000;
        margin-bottom: 30px;

        &__heading {
            width: 50%;
        }

        &__content {
            width: 50%;
            text-align: right;
        }
    }

    &__payment {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
