.gridjobs-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 24px;

  @include mq(lg) {
    flex-wrap: wrap;
    margin-left: -22px;
    margin-right: -22px;
    margin-top: 40px;
  }

  &--item {
    margin-bottom: 40px;
    flex: 0 0 100%;
    max-width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    min-height: 120px;
    font-family: var(--font-alt);
    &:hover {
      a {
        color: var(--second-gradient-color);
      }
    }
    @include mq(lg) {
      flex: 0 0 50%;
      max-width: 50%;
      padding-left: 22px;
      padding-right: 22px;
      margin-bottom: 74px;
    }

    &.type--deals {
      @include mq(lg) {
        flex: 0 0 33.33%;
        max-width: 33.33%;
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 28px;
      }

      .company-logo {
        @include mq(lg) {
          flex: 0 0 100px;
          width: 100px;
          height: auto;
        }
      }

      .company-info {
        @include mq(lg) {
          padding: 17px 14px;
        }

        &--header_info {
          font-size: 14px;
          @include mq(lg) {
            font-size: 14px;
          }
        }
      }

      & > .grid-divider {
        margin-right: 0;
        margin-left: 12px;

        @include mq(lg) {
          margin-right: 0;
          margin-left: 14px;
        }
      }
    }

    & > .grid-divider {
      width: 1px;
      height: 100%;
      background-color: #fff;
      margin-right: 0;
      margin-left: 12px;
      flex: 0 0 1px;

      @include mq(lg) {
        margin-right: 0;
        margin-left: 28px;
      }
    }

    .company-logo {
      flex: 0 0 100px;
      height: auto;
      width: 100px;
      display: flex;
      max-height: 100px;
      justify-content: center;
      @include mq(lg) {
        flex: 0 0 140px;
        width: 140px;
        max-height: 140px;
      }

      & > img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }

    .company-info {
      padding: 14px 12px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 140px;
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;

      @include mq(lg) {
        padding: 20px 28px;
        font-size: 16px;
        line-height: 20px;
      }

      &--header {
        font-weight: 700;
        &_headline {
          margin-bottom: 11px;
          @include mq(lg) {
            margin-bottom: 6px;
          }
        }

        &_info {
          font-weight: 400;
        }
        &--chips {
          display: flex;
          flex-direction: column;
          gap: 8px;
          margin-top: 10px;
          &_chip {
            background: white;
            border-radius: 27px;
            color: #0e0e0e;
            font-weight: 600;
            padding: 0 12px;
            font-size: 12px;
            width: fit-content;
            height: 22px;
            display: flex;
            align-items: center;
          }
        }
      }

      &--time {
        font-size: 12px;
        line-height: 15px;

        @include mq(lg) {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }

  &.for-you {
    display: flex;

    .gridjobs-grid--item {
      font-family: var(--font-body);

      & > .grid-divider {
        height: 90%;
        background-color: var(--tabler-header);
      }
    }

    @include mq(lg) {
      display: none;
    }
  }
}

.gridjobs-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.gridjobs-cta {
  display: flex;
  justify-content: center;
  align-items: center;
}
