@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');

body {
  background: #ffffff;
  font-family: "Jost", sans-serif;
  font-weight: 300;
  color: $body;
}
img {
  max-width: 100%;
  height: auto;
}
strong {
  font-weight: bold !important;
}

.roboto {
  font-family: Roboto, 'Nunito Sans', sans-serif;
}

.mt {
  &-22x {
    margin-top: 22px;
  }
  &-10 {
    margin-top: 40px;
  }
} 

.b-col{
  &-md {
    @media (min-width: 768px) {
      &-2 {
        columns: 2;
      }
    }
  }
}