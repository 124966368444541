.marketing{
  &__img{
    &__individual{
      max-height: 400px;
      width: 100%;
    }
  }
}
.hero__section__img {
  margin-left: 50px;
  margin-top: -30%;

  @include tablet {
      margin-left: 0;
      margin-top: 30px;
  }

  @include mobile {
      margin-left: 0;
      margin-top: -50px;
  }
}
