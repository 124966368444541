.components-header {
  width: 100%;
  padding-top: 35px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 390px;
  margin: 0 auto;
  padding-bottom: 60px;

  @include mqup(lg) {
    position: relative;
  }

  @include mq(lg) {
    padding-left: var(--layout-space-x);
    padding-right: var(--layout-space-x);
    padding-bottom: 105px;
    max-width: 1248px;
  }
  @include mq(xl) {
    padding-left: 51px;
    padding-right: 129px;
    max-width: 1440px;
  }

  .logo {
    width: 140px;
    height: 54px;

    & > img {
      width: 100%;
      height: 100%;
    }
  }

  .app-header-nav {
    display: none;

    @include mq(lg) {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .btn-nav {
      display: flex;
      padding: 10px 15px;
      background-color: var(--primary-color);
      color: var(--secondary-color);
      font-size: 16px;
      font-weight: 700;
      line-height: 13px;
      text-align: center;
      border-radius: var(--radius-lg);
      text-transform: unset;
      text-transform: capitalize;

      & + .btn-nav {
        margin-left: 10px;

        @include mq(xl) {
          margin-left: 23px;
        }
      }

      &#backBtn {
        margin: 0 auto 0 50px;
      }
    }
  }

  .hamburger-wrapper {
    --size: 61px;
    background-color: var(--primary-color);
    border-radius: var(--radius-lg);
    position: relative;
    z-index: 100;
    height: var(--size);
    width: var(--size);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.menu-mobile-show {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @include mq(lg) {
    display: none;
  }
}

.mobile-back-button {
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;

  .btn-nav {
    border-radius: 8px;
    background-color: var(--primary-color);
    color: var(--secondary-color);
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-size: 18px;
      font-weight: 800;
    }
  }

  @include mq(lg) {
    display: none;
  }
}
