h1 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 30px;
  display: inline-block;
}

h2 {
  font-size: 24px;
  font-weight: 500;
}

h3 {
  font-size: 20px;
  font-weight: 500;
}

p {
  font-size: 16px;
  font-weight: 300;
}

.h1-subheading {
  display: block;
  font-weight: 300;
  font-size: 20px;
  margin-top: -30px;

  @media only screen and (min-width: 768px) {
    display: inline-block;
    margin-top: 0;
  }
}

.txt {
  color: var(--primary-brand);
  &__edit {
    text-align: left;

    @media only screen and (min-width: 768px) {
      text-align: right;
    }
  }
  &__fs-18 {
    font-size: 18px;
  }
  &__fs-14 {
    font-size: 14px;
  }
  &__small {
    font-size: 14px;
  }

  &__smallest {
    font-size: 12px;
  }
  &__fw-400 {
    font-weight: 400 !important;
  }
  &__fw-500 {
    font-weight: 500 !important;
  }
  &__fw-600 {
    font-weight: 600 !important;
  }
  &__fw-700 {
    font-weight: 700 !important;
  }
  &__fw-900 {
    font-weight: 900 !important;
  }
  &__light {
    color: #a1a1a1 !important;
  }
  &__purple {
    color: $freelancer;
  }
  &-agency {
    color: $agency !important;
  }
  &-company {
    color: $company !important;
  }
  &-marketing {
    color: #eb605d !important;
  }
  &-black {
    color: #000000 !important;
  }
  &-freelancer {
    color: #8c79b5 !important;
  }
  &-white {
    color: #ffffff !important;
  }
  &-red {
    color: $red !important;
  }
}
.center {
  text-align: center;
}

.text-sm {
  font-size: 14px;
  line-height: 1.4;
}
