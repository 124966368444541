.app-tabler {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @include mq(lg) {
    grid-template-columns: repeat(16, 1fr);
  }

  &--column {
    flex: 1 1 0;
    word-break: break-word;
    padding: 10px 14px;
    font-weight: 500;
    @include mq(lg) {
      padding: 12px 21px;
    }

    &.full {
      width: 100%;
      .tb-body-info {
        width: 100%;
      }
    }

    &-header {
      display: none;
      word-break: break-word;
      padding: 10px 14px;
      color: var(--tabler-header);
      font-size: 14px;

      @include mq(lg) {
        display: block;
        padding: 12px 14px;
        border-width: 0 0 1px 0;
        border-color: var(--tabler-border-color);
        border-style: solid;

        &.no-border-bottom {
          border-bottom: unset;
        }
      }

      &.app-tabler--column.tb-push-right {
        justify-content: flex-start;

        @include mq(lg) {
          justify-content: flex-start;
          .l-right {
            align-items: flex-start;
          }
        }
      }

      &-grid {
        text-align: middle;
      }
    }

    &.tb-push-right {
      display: flex;
      justify-content: flex-start;

      @include mq(lg) {
        justify-content: flex-end;
        .l-right {
          align-items: flex-end;
        }
      }
    }
    &.tb-space-between {
      display: flex;
      justify-content: space-between;

      @include mq(lg) {
        justify-content: space-between;
        .l-right {
          align-items: space-between;
        }
      }
    }

    &-body {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0;

      &.last-column {
        margin-bottom: 40px;
        @include mq(lg) {
          margin-bottom: 0;
        }
      }
    }

    &-grid {
      border-width: 0;
      border-color: var(--tabler-border-other-color);
      border-style: solid;

      @include mq(lg) {
        border-width: 0 0 1px 0;
      }

      &-header {
        display: none;
        word-break: break-word;
        padding: 10px 14px;
        color: var(--tabler-header);
        font-size: 14px;
        text-align: center;

        @include mq(lg) {
          display: block;
          padding: 12px 14px;
          border-width: 0 0 1px 0;
          border-color: var(--tabler-border-color);
          border-style: solid;

          &.no-border-bottom {
            border-bottom: unset;
          }
        }

        &.app-tabler--column.tb-push-right {
          justify-content: flex-start;

          @include mq(lg) {
            justify-content: flex-start;
            .l-right {
              align-items: flex-start;
            }
          }
        }
      }

      &-body {
        height: 90px;
        display: table;

        &.first-column-grid,
        &.last-column-grid {
          text-align: center;
        }
      }

      .tb-body-info {
        display: table-cell !important;
        vertical-align: middle;

        img {
          @include mq(lg) {
            width: 60px;
            height: 60px;
            object-fit: contain;
          }
        }
      }
    }

    &.no-header-mobile {
      .tb-body-info:before {
        display: none;
      }
    }

    .text-indent-left {
      text-indent: 14px;
      color: var(--text-tabler-header-color);
    }
  }

  .tb-span-1 {
    grid-column: span 2;
    @include mq(lg) {
      grid-column: span 1;
    }
  }
  .tb-span-2 {
    grid-column: span 2;

    @include mq(lg) {
      grid-column: span 2;
    }
  }
  .tb-span-3 {
    grid-column: span 2;

    @include mq(lg) {
      grid-column: span 3;
    }
  }
  .tb-span-4 {
    grid-column: span 2;

    @include mq(lg) {
      grid-column: span 4;
    }
  }
  .tb-span-5 {
    grid-column: span 2;

    @include mq(lg) {
      grid-column: span 5;
    }
  }

  .tb-span-6 {
    grid-column: span 2;

    @include mq(lg) {
      grid-column: span 6;
    }
  }

  .tb-span-7 {
    grid-column: span 2;

    @include mq(lg) {
      grid-column: span 7;
    }
  }

  .tb-span-8 {
    grid-column: span 2;

    @include mq(lg) {
      grid-column: span 8;
    }
  }

  .tb-span-16 {
    grid-column: span 2;

    @include mq(lg) {
      grid-column: span 16;
    }
  }

  .tb-span-7-push {
    grid-column: span 2;

    @include mq(lg) {
      grid-column: span 7 / 17;
    }
  }

  .tb-remove-inline-padding {
    width: 100%;
    .tb-body-info {
      & > .tb-body-info--content {
        flex: 2 1 0;
        padding: 10px 14px;
        display: inline-block;

        @include mq(lg) {
          padding: 12px 0 !important;
        }
      }

      &.d-none {
        display: none;
      }
    }
  }

  .tb-body-info {
    width: 100%;
    display: flex;
    @include mq(lg) {
      width: auto;
    }
    & > .tb-body-info--content {
      flex: 2 1 0;
      padding: 10px 14px;
      display: inline-block;
      font-size: 14px;
      font-weight: 500;
      @include mq(lg) {
        padding: 12px 14px;
      }

      p,
      span {
        font-weight: 500;
      }
    }

    &:before {
      display: inline-block;
      content: attr(data-name);
      flex: 0 0 95px;
      padding: 10px 14px 10px 0;
      border-right: 1px solid var(--tabler-border-color);
      color: var(--tabler-header);
      text-align: right;
      font-size: 14px;
      font-weight: 500;
      @include mq(lg) {
        display: none;
      }
    }
  }
  .section-side-on-side {
    display: flex;
    margin-top: 22px;
    flex-wrap: wrap;

    @include mq(lg) {
      margin-top: 32px;
    }

    & > * {
      flex: 0 0 100%;
      @include mq(lg) {
        flex: 0 0 67%;
        max-width: 67%;
      }

      & + * {
        @include mq(lg) {
          flex: 0 0 33%;
          max-width: 33%;
        }
      }
    }
    .on-l-side {
      border-right: unset;
      border-color: var(--tabler-border-color);
      border-bottom: 1px solid;

      @include mq(lg) {
        border-right: 1px solid;
        border-bottom: unset;
      }
    }
    .on-r-side {
      display: flex;
      align-items: center;
      padding: 22px 0 22px 22px;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      .leading {
        font-size: 14px;
        font-weight: 500;
        line-height: 20.23px;
        margin-bottom: 11px;
        color: var(--text-tabler-header-color);
      }
    }
  }

  .hidden-content {
    .tb-body-info > .tb-body-info--content {
      padding: 0;
    }
  }

  &-hide-on-mobile {
    display: none;

    @include mq(lg) {
      display: block;
    }
  }
}

.tabler-cta-grid,
.tabler--cta {
  display: flex;
  flex-direction: column;

  @include mq(lg) {
    flex-direction: row;
  }

  a:hover {
    text-decoration: underline;
  }

  & > * {
    display: block;
    flex: 0 0 auto;
    margin-bottom: 22px;
    &.app-btn-primary {
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
    & + * {
      margin-top: 12px;
      @include mq(lg) {
        margin-left: 25px;
        margin-bottom: 0;
        margin-top: 0;
      }
    }
  }

  &.between {
    justify-content: space-between;
  }
}

.app-tabler .no-header-mobile .tb-body-info > span {
  padding-top: 16px;
}

.child-hide {
  .tb-body-info {
    &:before,
    .tb-body-info--content {
      display: none;
    }
  }
}

.table-applicants {
  .tb-body-info::before {
    flex: 0 0 72px;
  }
}

.app-tabler-simply {
  display: table;
  border-collapse: collapse;
  font-size: 8px;
  width: 100%;

  @include mq(md) {
    font-size: 12px;
  }

  &--header {
    color: var(--text-tabler-header-color);
    border-bottom: 1px solid var(--tabler-border-other-color);
  }

  &--row {
    display: table-row;
    width: 100%;
  }

  &--column {
    display: table-cell;
    padding: 6px 6px;
  }
}
