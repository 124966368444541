.blog {
  &__banner {
    display: flex;
    align-items: center;
    height: 300px;
    box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.1);
    margin-bottom: 50px;
    @include tablet {
      box-shadow: none;
      height: unset;
      margin-bottom: 40px;
    }
    &__img {
      max-height: 300px;
      object-position: center;
      object-fit: cover;
      padding-right: 15px;
      width: 100%;
      @include tablet {
        padding-right: 0;
      }
      @include mobile {
        width: 100%;
        height: 200px;
      }
    }
    &__heading {
      font-weight: 600;
      font-size: 36px;
      line-height: 42px;
      color: #8c79b5;
      margin-bottom: 0;
      padding-right: 15px;
      padding-left: 15px;
      @include tablet {
        font-size: 24px;
        line-height: 32px;
        padding: 10px 0 0;
        margin-bottom: 40px;
        padding-right: 0;
        padding-left: 0;
      }
      @include mobile {
        font-size: 16px;
        line-height: 24px;
        padding: 10px 0 0;
        margin-bottom: 40px;
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
  &__img {
    margin-bottom: 10px;
    width: 100%;
    height: 233px;
    object-fit: cover;
    @media (max-width: 1024px) {
      height: 193px;
    }
    @media (max-width: 768px) {
      height: 220px;
    }
    @media (max-width: 768px) {
      height: 230px;
    }
  }
}
