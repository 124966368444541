.app-badge {
  display: flex;
  padding: 8px 12px;
  color: var(--text-primary-color);
  border: 1px solid var(--text-primary-color);
  border-radius: 100px;
  line-height: 8px;
  font-size: 14px;
  letter-spacing: 0;
  transition: var(--t-duration);
  justify-content: center;
  align-items: center;

  &.app-badge {
    &-primary {
      // background: radial-gradient(
      //   74.13% 291.65% at 25.87% 81.1%,
      //   var(--primary-color) 0%,
      //   var(--primary-color) 100%
      // );
      background: unset;
      background-size: 100% 100%;
      &:hover,
      &.active {
        // background: radial-gradient(
        //   74.13% 291.65% at 25.87% 81.1%,
        //   var(--third-gradient-color) 0%,
        //   var(--first-gradient-color) 100%
        // );
        background: var(--btn-bg-xxl);
        background-size: 100% 100%;
      }
      &.as-checkbox {
        padding: 4px 12px 4px 5px;
        display: flex;
        margin-bottom: 12px;

        & > * {
          & + * {
            margin-left: 5px;
          }
        }
      }

      .check-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 16px;
        height: 16px;
        background: #0E0E0E;
        border: 1px solid #FFFFFF;
        border-radius: 32px;

      }
    }
  }

  &.chip {

  }
}


.section-chips {
  margin-top: 23px;
  margin-bottom: 20px;
  padding: 0;

  @include mq(lg){
    margin-top: 19px;
    margin-bottom: 22px;
  }

  &--blocks {
    display: flex;
    margin-left: -6px;
    margin-right: -6px;
   flex-wrap: wrap;

    & > * {
      flex: 0 0 auto;
      padding: 0 6px;
    }
  }
}