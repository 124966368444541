.grid {
    display: grid;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 70px;

    @media only screen and (min-width: 768px) {
        margin-top: -20px;
    }

    &__applications {
        grid-template-columns: 1fr 1fr;

        @media only screen and (min-width: 576px) {
            grid-template-columns: 3fr 1fr 1fr;
        }

        @media only screen and (min-width: 992px) {
            grid-template-columns: 2fr 1fr 1fr 2fr 3fr 2fr 1fr;
        }
    }

    &__applicants {
        grid-template-columns: 1fr 1fr;

        @media only screen and (min-width: 576px) {
            grid-template-columns: 3fr 1fr 1fr;
        }

        @media only screen and (min-width: 992px) {
            grid-template-columns: 2fr 1.5fr 3fr 2fr 1fr;
        }
    }

    &__opportunities {

        &__active {
            grid-template-columns: 1fr 1fr;

            @media only screen and (min-width: 576px) {
                grid-template-columns: 2fr 1fr 1fr 1fr 3fr;
            }

            @media only screen and (min-width: 992px) {
                grid-template-columns: 3fr 1.5fr 1.5fr 1fr 1fr 2fr;
            }
        }
    }

    &__ul {
        list-style-type: none;
        margin-bottom: 0px;
        padding-inline-start: 0px;

        &__li {
            display: inline-block;
            margin-right: 25px;

            @media only screen and (min-width: 992px) {
                margin-right: 15px;
            }
        }

        &__li:last-of-type {
            margin-right: 0;
        }
    }

    &__last {
        margin-bottom: 20px;
    }

    &__credits {
        grid-template-columns: repeat(3, 1fr);

        @media only screen and (min-width: 576px) {
            grid-template-columns: repeat(4, 1fr);
            margin-top: 0;
        }

        @media only screen and (min-width: 992px) {
            grid-template-columns: repeat(5, 1fr);
            margin-top: 0;
        }
    }

}

.grid > span {
    padding: 12px 15px 12px 0px;
}

.grid > span.last-span {
    padding-right: 0;
}

.grid-heading {
    border-bottom: 1px solid black;
    font-weight: 500;
}

.grid-content {
    border-bottom: 1px solid lightgrey;
    font-weight: 300;
}

.profile-link, .edit-close {
    text-align: right;
}
