.ReactTags {
    color: $company-light;

    &__tags {
        position: relative;
    }

    &__tag {
        @extend .ReactTags;
        background-color: $company;
        padding: 5px 10px;
        display: inline-block;
        margin: 0 7.5px 7.5px 0;
    }

    &__remove {
        @extend .ReactTags;
        text-decoration: none;
        cursor: pointer;
        margin-left: 10px;
    }

    &__suggestions {
        position: absolute;
        z-index: 10;
        background-color: $company-light;

        ul {
            list-style-type: none;
            padding: 0;
            background: $company-light;
            width: 200px;
            height: auto;

            li {
                border-bottom: 1px solid #ddd;
                padding: 5px 10px;
                margin: 0;

                mark {
                    text-decoration: underline;
                    background: none;
                    font-weight: 600;
                }
            }
        }
    }

    &__activeSuggestion {
        @extend .ReactTags;
        background-color: $company;
        cursor: pointer;
        color: $company-light;

        mark {
            @extend .ReactTags;
        }
    }
}

.freelancer-tag {
    @extend .ReactTags;
    background-color: $freelancer;
    padding: 5px 10px;
    display: inline-block;
    margin: 0 7.5px 7.5px 0;
}

.freelancer-active-suggestion {
    @extend .ReactTags;
    background-color: $freelancer;
    cursor: pointer;
    color: $freelancer-light;

    mark {
        @extend .ReactTags;
    }
}
