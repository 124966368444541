a {
    text-decoration: none;
    font-weight: 500;
}

a:hover {
    text-decoration: underline;
}
a.form__btn:hover {
    text-decoration: none;
    color: #fff;
}
a.form__btn-outline-purple:hover {
    text-decoration: none;
}

.nochange {
    &:hover {
        text-decoration: none;
    }
}

.mobile-menu a,
.header a,
.header__link,
.footer a {
    color: #ffffff;
    text-decoration: none;
}

.header__link:hover {
    color: #ffffff;
    text-decoration: none;
}

.header__link:after {
    display: block;
    content: "";
    border-bottom: solid 2px #ffffff;
    transform: scaleX(0);
    transition: transform 200ms ease-in-out;
}

.header__link:hover:after {
    transform: scaleX(1);
}

.agency-account-submenu a {
    font-weight: 300;
}

.pay-with-card {
    background-color: #237b9f;
    color: #ffffff;
    font-weight: 700;
    letter-spacing: 1px;
    padding: 10px 20px;
    margin: auto;
    text-transform: uppercase;
    text-decoration: none;
    transition: 300ms all ease-in-out;
}

.pay-with-card:hover {
    color: #ffffff;
    text-decoration: none;
    transform: scale(1.05);
}

.use-another-card {
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    margin: 5px 0;
    text-decoration: none;
}

.use-another-card:hover {
    text-decoration: underline;
}

.footer a:hover {
    text-decoration: underline;
}
.link {
    cursor: pointer;
    color: var(--primary-brand, inherit);
    &:hover {
        color:var(--primary-brand, inherit);
    }

    &-white {
        color: #ffffff;
    }

    &-white:hover {
        color: #ffffff;
    }
    &-freelancer {
        color: #8c79b5;
    }

    &-freelancer:hover {
        color: #8c79b5;
    }
    &-agency {
        color: $agency;
    }

    &-agency:hover {
        color: $agency;
    }
    &-company {
        color: $company;
    }
    &-company:hover {
        color: $company;
    }

    &-marketing {
        color: #eb605d;
    }

    &-marketing:hover {
        color: #eb605d;
    }
    &-black {
        color: #000000;
    }

    &-black:hover {
        color: #000000;
    }

    &-gray {
        color: #929292;
    }

     &-gray:hover {
        color: #929292;
    }
}

.freelancer {
    --text-color: $freelancer;
    color: $freelancer;
}

.agency {
    --text-color: $agency;
    color: $agency;
}

.company {
    --text-color: $company;
    color: $company;
}
