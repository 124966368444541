.loader_2 {
  margin: 13px auto;
  font-size: 8px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load5 1.1s infinite ease;
  animation: load5 1.1s infinite ease;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
@-webkit-keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #c0bebe, 1.8em -1.8em 0 0em #d4c8c8c5, 2.5em 0em 0 0em #d4c8c8c5, 1.75em 1.75em 0 0em #d4c8c8c5, 0em 2.5em 0 0em #d4c8c8c5, -1.8em 1.8em 0 0em #d4c8c8c5, -2.6em 0em 0 0em rgba(255, 255, 255, 0.4), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em #c0bebe, 2.5em 0em 0 0em #d4c8c8c5, 1.75em 1.75em 0 0em #d4c8c8c5, 0em 2.5em 0 0em #d4c8c8c5, -1.8em 1.8em 0 0em #d4c8c8c5, -2.6em 0em 0 0em #d4c8c8c5, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.4);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.4), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em #c0bebe, 1.75em 1.75em 0 0em #d4c8c8c5, 0em 2.5em 0 0em #d4c8c8c5, -1.8em 1.8em 0 0em #d4c8c8c5, -2.6em 0em 0 0em #d4c8c8c5, -1.8em -1.8em 0 0em #d4c8c8c5;
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em #d4c8c8c5, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.4), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em #c0bebe, 0em 2.5em 0 0em #d4c8c8c5, -1.8em 1.8em 0 0em #d4c8c8c5, -2.6em 0em 0 0em #d4c8c8c5, -1.8em -1.8em 0 0em #d4c8c8c5;
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em #d4c8c8c5, 1.8em -1.8em 0 0em #d4c8c8c5, 2.5em 0em 0 0em rgba(255, 255, 255, 0.4), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em #c0bebe, -1.8em 1.8em 0 0em #d4c8c8c5, -2.6em 0em 0 0em #d4c8c8c5, -1.8em -1.8em 0 0em #d4c8c8c5;
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em #d4c8c8c5, 1.8em -1.8em 0 0em #d4c8c8c5, 2.5em 0em 0 0em #d4c8c8c5, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.4), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em #c0bebe, -2.6em 0em 0 0em #d4c8c8c5, -1.8em -1.8em 0 0em #d4c8c8c5;
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em #d4c8c8c5, 1.8em -1.8em 0 0em #d4c8c8c5, 2.5em 0em 0 0em #d4c8c8c5, 1.75em 1.75em 0 0em #d4c8c8c5, 0em 2.5em 0 0em rgba(255, 255, 255, 0.4), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em #c0bebe, -1.8em -1.8em 0 0em #d4c8c8c5;
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em #d4c8c8c5, 1.8em -1.8em 0 0em #d4c8c8c5, 2.5em 0em 0 0em #d4c8c8c5, 1.75em 1.75em 0 0em #d4c8c8c5, 0em 2.5em 0 0em #d4c8c8c5, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.4), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em #c0bebe;
  }
}
@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #c0bebe, 1.8em -1.8em 0 0em #d4c8c8c5, 2.5em 0em 0 0em #d4c8c8c5, 1.75em 1.75em 0 0em #d4c8c8c5, 0em 2.5em 0 0em #d4c8c8c5, -1.8em 1.8em 0 0em #d4c8c8c5, -2.6em 0em 0 0em rgba(255, 255, 255, 0.4), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em #c0bebe, 2.5em 0em 0 0em #d4c8c8c5, 1.75em 1.75em 0 0em #d4c8c8c5, 0em 2.5em 0 0em #d4c8c8c5, -1.8em 1.8em 0 0em #d4c8c8c5, -2.6em 0em 0 0em #d4c8c8c5, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.4);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.4), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em #c0bebe, 1.75em 1.75em 0 0em #d4c8c8c5, 0em 2.5em 0 0em #d4c8c8c5, -1.8em 1.8em 0 0em #d4c8c8c5, -2.6em 0em 0 0em #d4c8c8c5, -1.8em -1.8em 0 0em #d4c8c8c5;
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em #d4c8c8c5, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.4), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em #c0bebe, 0em 2.5em 0 0em #d4c8c8c5, -1.8em 1.8em 0 0em #d4c8c8c5, -2.6em 0em 0 0em #d4c8c8c5, -1.8em -1.8em 0 0em #d4c8c8c5;
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em #d4c8c8c5, 1.8em -1.8em 0 0em #d4c8c8c5, 2.5em 0em 0 0em rgba(255, 255, 255, 0.4), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em #c0bebe, -1.8em 1.8em 0 0em #d4c8c8c5, -2.6em 0em 0 0em #d4c8c8c5, -1.8em -1.8em 0 0em #d4c8c8c5;
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em #d4c8c8c5, 1.8em -1.8em 0 0em #d4c8c8c5, 2.5em 0em 0 0em #d4c8c8c5, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.4), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em #c0bebe, -2.6em 0em 0 0em #d4c8c8c5, -1.8em -1.8em 0 0em #d4c8c8c5;
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em #d4c8c8c5, 1.8em -1.8em 0 0em #d4c8c8c5, 2.5em 0em 0 0em #d4c8c8c5, 1.75em 1.75em 0 0em #d4c8c8c5, 0em 2.5em 0 0em rgba(255, 255, 255, 0.4), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em #c0bebe, -1.8em -1.8em 0 0em #d4c8c8c5;
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em #d4c8c8c5, 1.8em -1.8em 0 0em #d4c8c8c5, 2.5em 0em 0 0em #d4c8c8c5, 1.75em 1.75em 0 0em #d4c8c8c5, 0em 2.5em 0 0em #d4c8c8c5, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.4), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em #c0bebe;
  }
}
