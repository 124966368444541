.start-subscription {

    &__box {
        background-color: #E9F2F5;
        padding: 30px;
        text-align: center;
        margin-bottom: 40px;

        &__header {
            font-size: 40px;
            font-weight: 700;
            line-height: 35px;
        }

        &__subheader {
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 20px;
        }

        &__caps {
            font-size: 18px;
            font-weight: 500;
            text-transform: uppercase;
            margin-bottom: 10px;
        }
    }
}
