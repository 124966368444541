
.page-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    min-height: 100vh;
  }
  .page-layout {
    position: relative;
    z-index: 2;
    height: 100vh;
    overflow: auto;
  }

.page-content {
  &-layout {
    display: flex;
    padding-left: var(--layout-space-x);
    padding-right: var(--layout-space-x);
    align-items: center;
    flex-direction: column;
    padding-bottom: 60px;
    min-height: 45vh;
    @include mq(lg){
      padding-bottom: 184px;
    }

    & > div {
      width: 100%;
    }

    &--no-bottom {
      padding-bottom: 60px;
    }
    &--extra-space-t {
      padding-top: 55px;

      @include mq(lg){
        padding-top: 65px;
      }
    }
  }
}

.space-t--xxl {
  margin-top: 65px;

  @include mq(lg){
    margin-top: 110px;
  }
}

.theme .blog-content{
  p{
    margin-bottom: 1.4rem;
  }
}