.account-submenu {

        &__mobile {
        font-size: 18px;
        margin: -50px 0px 30px 0px;
        overflow: hidden;

        @media only screen and (min-width: 992px) {
            display: none;
        }

        &__fixed {
            padding: 15px 0px;
            z-index: 2;
            position: relative;
        }

        &__heading {
            font-weight: 500;
        }

        &__arrow {
            text-align: right;
            padding-right: 25px;
            z-index: 5;
        }

        &__content {
            padding: 0px 15px 15px 15px;
            font-weight: 300;
            margin-top: -210px;
            transition: 300ms all ease-in-out;
            z-index: 1;
            position: relative;
        }
    }

    &__desktop {

        display: none;

        @media only screen and (min-width: 992px) {
            padding: 20px 20px 30px 20px;
            display: inline-block;
            float: right;

            &.submenu-v2 {
                padding-top: var(--submenu-space-top, 116px);
                display: block;
                float: none;
                padding-left: var(--submenu-space-left, 82px);
                padding-right: var(--submenu-space-right, 82px);
            }
        }


        &__heading {
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 15px;
        }

    }



    &__list {
        display: flex;
        flex-direction: column;
        list-style-type: none;
        padding-inline-start: 0px;
        margin-bottom: 0px;

        &__item {
            padding-bottom: 5px;
            font-size: 17px;

            @media only screen and (min-width: 768px) {
                font-size: 16px;
            }
        }

        &__item:last-of-type {
            padding-bottom: 0px;
        }
    }

}

.open {
    margin-top: 0px;
}

.fa-chevron-down {
    transition: 300ms all ease-in-out;
}

.oneeighty {
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.col-submenu {
    flex: 0 0 350px;
    height: 100%;
    position: absolute;
    right: 0;
    

    @media (max-width: 991px) {
        display: none;
    }

    @media (min-width: 1440px) {
        flex: 0 0 435px;
        right: -150px;
    } 
}

.row-main-submenu {
    justify-content: center;
    @media (min-width: 992px) {
        justify-content: flex-start;
    }
    @media (min-width: 1440px) {
        position: relative;
      }
}


.col-main-submenu { 
    
    @media (min-width: 992px) {
        max-width: 67%;
        min-height: 636px;
    }
    @media (min-width: 1440px) {
      max-width: 817px;
      padding-right: 107px;
    }
  }
 