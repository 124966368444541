.Toastify__toast--success {
    background-color: #71c0b3 !important;
    font-size: 16px !important;
    font-family: var(--font-heading) !important;
    border-radius: 12px !important;
    font-weight: 700 !important;
}

.Toastify__toast--error {
    background-color: #9A1B3E !important;
    font-size: 16px !important;
    font-family: var(--font-heading) !important;
    border-radius: 12px !important;
    font-weight: 700 !important;
}

.Toastify__toast {
    min-height: 56px !important;
    padding: 20px !important;
}

.Toastify__close-button {
    opacity: unset !important;
    align-self: center !important;
    line-height: 0 !important;
}

.Toastify__toast-container {
    width: 380px;
}
.Toastify__close-button>svg {
    vertical-align: unset !important;
}