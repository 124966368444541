.header {
    &__list {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        list-style-type: none;
        padding-inline-start: 0px;
        margin-bottom: 0px;
        width: 100%;

        &__item {
            margin-right: 20px;
            text-align: right;
            font-size: 18px;
            background: black;
            padding: 12px 27px;
            border-radius: 12px;
            display: block;
        }

        &__item:last-of-type {
            margin-right: 0px;
        }
    }
}

.header {
    &-company,
    &-agency
     {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $company;
        width: 100%;
        height: 80px;
        margin-bottom: 50px;
    
        &__inner {
            &__logo {
                display: flex;
                align-items: center;
            }
    
            &__menu {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                color: #ffffff;
            }
        }
    
        @media only screen and (min-width: 576px) {
            height: 125px;
        }
    }
        @media only screen and (min-width: 576px) {
            padding-inline: 50px;
        }
    &-agency {
        background-color: $agency;
    }
}

.header-freelancer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #8c79b5;
    width: 100%;
    height: 80px;
    margin-bottom: 50px;

    &__inner {
        &__logo {
            display: flex;
            align-items: center;
        }

        &__menu {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            color: #ffffff;
        }
    }

    @media only screen and (min-width: 576px) {
        height: 125px;
    }
}

.header-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    margin-bottom: 50px;

    &__inner {
        &__logo {
            display: flex;
            align-items: center;
        }

        &__menu {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            color: #ffffff;
        }
    }

    @media only screen and (min-width: 576px) {
        height: 125px;
    }
}

.header-marketing {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eb605d;
    width: 100%;
    height: 80px;
    margin-bottom: 50px;

    &__list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        list-style-type: none;
        padding-inline-start: 0px;
        margin-bottom: 0px;
        width: 100%;

        &__item {
            text-align: right;
            font-size: 18px;
            z-index: 10;
        }

        &__item:last-of-type {
            margin-right: 0px;
        }
    }
    &__inner {
        &__logo {
            display: flex;
            align-items: center;
        }

        &__menu {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            color: #ffffff;
            max-width: 924px;
            margin: 0 auto;
        }
    }

    @media only screen and (min-width: 576px) {
        height: 125px;
    }
}
