.theme {
  --primary-color: #929292;
  --secondary-color: #000;
  --accent-color: #ffde66;
  --primary-brand: #71bfb2;
  --secondary-brand: #d9f9f4;
  --third-brand: #f0f6f8;
  --primary-brand-20: #71bfb233;
  --monochrome-color: #dadada;
  --input-bg-coolor: #f2f2f2;
  --netral-color: #fff;
  --section-space-top: 53px;
  --section-space-bottom: 76px;
  --submenu-space-top: 116px;
  --submenu-space-bottom: 42px;
  --submenu-space-left: 82px;
  --submenu-space-right: 164px;

  @media (max-width: 991px) {
    --section-space-top: 53px;
    --section-space-bottom: 76px;
    --submenu-space-top: 18px;
    --submenu-space-bottom: 18px;
    --submenu-space-left: 15px;
    --submenu-space-right: 15px;
  }

  @media (max-width: 1439px) {
    --submenu-space-left: 50px;
    --submenu-space-right: 105px;
  }

  &-agency {
    --primary-color: #929292;
    --secondary-color: #50524e;
    --accent-color: #ffde66;
    --primary-brand: #71bfb2;
    --secondary-brand: #d9f9f4;
    --third-brand: #f0f6f8;
    --monochrome-color: #dadada;
    --netral-color: #fff;
  }
  &-freelancer {
    --primary-color: #929292;
    --secondary-color: #50524e;
    --primary-brand: $freelancer;
    --secondary-brand: #fff;
    --monochrome-color: #dadada;
    --netral-color: #fff;
  }
  &-company {
    --primary-color: #929292;
    --secondary-color: #50524e;
    --primary-brand: $company;
    --secondary-brand: $company-mid;
    --third-brand: $company-light;
    --monochrome-color: #dadada;
    --netral-color: #fff;
  }
}

.text-primary {
  color: var(--primary-color) !important;
}

.section-spacer {
  &-y {
    padding-top: var(--section-space-top, 53px);
    padding-bottom: var(--section-space-bottom, 76px);
  }
}

.col-right-xs {
  flex: 0 0 100%;
  padding-left: 16px;
  padding-right: 16px;
  max-width: 480px;

  @media (min-width: 768px) {
    max-width: 452px;
  }
  @media (min-width: 992px) {
    flex: 1 1 0;
    padding-left: 30px;
    padding-right: 15px;
    max-width: 500px;
  }

  @media (min-width: 1200px) {
    padding-left: 71px;
    padding-right: 0;
  }
}
.page-h1 {
  font-weight: 900;
  color: var(--secondary-color);
}
.col-left-xs {
  flex: 0 0 100%;
  max-width: 500px;
  padding-left: 16px;
  padding-right: 16px;
  @media (min-width: 768px) {
    max-width: 200px;
  }
  @media (min-width: 992px) {
    max-width: 170px;
    padding-left: 15px;
    padding-right: 15px;
    flex: 0 0 170px;
  }

  @media (min-width: 1200px) {
    flex: 0 0 230px;
    max-width: 230px;
  }
}

.file__upload__avatar {
  padding-top: 100%;
  position: relative;

  .field__upload {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    .up-container {
      padding: 0 15px;

      & > img {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        max-width: unset;
      }
    }
    .up-dashed {
      background-image: none;
      height: 100%;

      .up-text {
        text-align: center;
        font-size: 1rem;
        @media (min-width: 992px) {
          font-size: 13px;
        }
        @media (min-width: 1440px) {
          font-size: 1rem;
        }
      }
    }
  }
}

.expertise-tag {
  border: 1px solid var(--primary-color, #929292);
  color: var(--primary-color, #929292);
  padding: 5px 12px 5px 5px;
  border-radius: 999px;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  display: flex;
  cursor: pointer;

  .check-circle {
    width: 16px;
    height: 16px;
    border: 1px;
    border-radius: 999px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    border: 1px solid var(--primary-color);

    svg {
      display: none;
    }
  }

  .tag-label {
    margin-left: 5px;
  }

  &.checked {
    background-color: var(--primary-brand);
    color: var(--netral-color, #fff);
    border-color: var(--primary-brand);

    .check-circle {
      border-color: var(--netral-color, #fff);
      background-color: var(--netral-color, #fff);
      color: var(--primary-brand);

      svg {
        display: inline-block;
      }
    }
  }
}

.space {
  &-x {
    &-3 {
      & > * {
        margin-left: 0.75rem;
      }
    }
  }
}
.space {
  &-y {
    &-3 {
      & > * {
        margin-bottom: 0.75rem;
      }
    }
  }
}

.label-push-l {
  padding-left: 10px;
}

.app-label {
  font-size: 14px;
  font-weight: 700;
  line-height: 19.1px;
  color: var(--primary-color, #929292);
}
