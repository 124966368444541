.theme { 

@include addcssvariables('--', '', $theme-root);
@include addcssvariables('--text-', '-color', $text-colors);

@include mq(lg) {
  @include addcssvariables('--', '', $theme-root-tablet-up);
}

p {
  margin-bottom: 0;
}

color: var(--text-primary-color);

 a {
  &:hover {
    color: var(--second-gradient-color)
  }
 }
}
