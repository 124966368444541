.purchased-spent {
    margin-bottom: 40px;

    &__ul {
        list-style-type: none;
        margin-bottom: 0px;
        padding-inline-start: 0px;
        display: flex;

        &__li {
            display: inline-block;
            width: 50%;
        }

        &__li:last-of-type {
            text-align: right;
        }
    }
}
