.reason-input::placeholder {
  font-style: italic;
}
.h{
  &-full {
    height: 100%;
  }
}
.fs {
  &-28x {
    font-size: 28px;
  }
}
.leading {
  &-none {
    line-height: 0;
  }
}

.max-w {
  &-v2 {
    max-width: 430px;
  }
}

.react-tel-input .selected-flag:focus {
  background-color: unset !important;
}

// .react-tel-input .selected-flag:hover {
//   background-color: unset!important;
// }