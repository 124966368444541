.text {
    &__gray {
        color: #929292;
        &--dark {
            color: #50524E;
        }
    } 
    &__white {
        color: #ffffff;
    }
}