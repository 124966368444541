:root {
  --font-serif: sans-serif;
  --font-bold: 700;
  --font-semi-bold: 600;
  --font-medium: 500;
  --font-normal: 400;
  --font-thin: 300;
  --font-light: 200;
  --font-heading: 'Jost', Arial, Helvetica, sans-serif;
  --font-body: 'Jost', Arial, Helvetica, sans-serif;
  --font-alt: 'Space Grotesk', Arial, Helvetica, sans-serif;
}

html {
  scroll-behavior: smooth;
}

html,
body {
  margin: 0;
  font-family: var(--font-body);
  font-weight: var(--font-medium);
  line-height: 1.4em;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3 {
  font-weight: var(--font-bold);
  font-family: var(--font-heading);
}

h4,
h5,
h6 {
  font-weight: var(--font-medium);
  font-family: var(--font-heading);
}

textarea,
input,
select {
  &:focus-visible {
    outline: none;
  }
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.main-wrapper {
  display: block;
  position: relative;
  min-height: 100vh;
}

.app-hide-on-mobile {
  display: none;
  @include mq(lg) {
    display: block;
  }
}
.app-hide-on-desktop {
  display: block;
  @include mq(lg) {
    display: none;
  }
}

body .field .error-state {
  &.field-input {
    border-width: 3px;
    border-color: var(--error-primary);
    color: var(--error-primary);
    background-color: var(--error-accent);

    &::placeholder {
      color: var(--error-primary);
    }
  }
}

.aspect-ratio {
  &-square {
    position: relative;

    @include mq(lg) {
      &:before {
        content: '';
        padding-top: 100%;
        display: block;
      }
    }

    & > img {
      width: 100%;
      // height: 320px;
      object-fit: contain;
      top: 0;
      left: 0;

      &.cover {
        object-fit: cover;
      }

      @include mq(lg) {
        height: 100%;
        position: absolute;
      }
    }
  }

  &-rectangle {
    position: relative;
    &:before {
      content: '';
      padding-top: 40%;
      display: block;
    }
  }
}

.spacer-t {
  margin-top: 30px;

  @include mq(lg) {
    margin-top: 40px;
  }

  &.thin {
    margin-top: 25px;

    @include mq(lg) {
      margin-top: 25px;
    }
  }

  &.thick {
    margin-top: 51px;

    @include mq(lg) {
      margin-top: 51px;
    }
  }
  &.thick-xl {
    margin-top: 65px;

    @include mq(lg) {
      margin-top: 100px;
    }
  }
}

.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}
.frame-rounded {
  border-radius: 999px;
  overflow: hidden;
}

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-contain {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.meta-header {
  &--grid {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
