.panel-back {
  --px: 20px;
  --py: 32px;
  --mx: 40px;
  --max-width: 360px;
  --panel-radius: 26px;
  @include mq(lg) {
    --px: 58px;
    --py: 65px;
    --mx: 50px;
    --max-width: 1180px;
    --panel-radius: 36px;
  }

  &.panel-transparent {
    background-color: transparent;
    border-radius: 0;
    padding: 0;
  }

  background-color: var(--primary-color);
  max-width: var(--max-width);
  border-radius: var(--panel-radius);
  padding-left: var(--px);
  padding-right: var(--px);
  padding-top: var(--py);
  padding-bottom: var(--py);
  margin-bottom: var(--mx);

  table {
    width: 100% !important;
    color: black;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &--sm {
    --px: 20px;
    --py: 75px;
    --max-width: 360px;
    @include mq(lg) {
      --px: 43px;
      --py: 65px;
      --max-width: 525px;
    }
    max-width: var(--max-width);
  }
  &--st {
    --py: 32px;

    @include mq(lg) {
      --py: 65px;
      --px: 58px;
    }

    max-width: var(--max-width);
  }
}
