.job-type,
.compensation,
.share,
.opportunity-type,
.opportunity-location {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  box-sizing: border-box;

  &__card {
    background: $freelancer-light;
    padding: 10px;
    font-size: 16px;
    text-align: center;
    margin-top: 20px;
    transition: 200ms all ease-in-out;
    cursor: pointer;
    border: 2px solid $freelancer-light;
    white-space: nowrap;
    margin-left: 5px;
    margin-right: 5px;

    &__active {
      background: $freelancer;
      border: 2px solid $freelancer;
      color: white;
    }

    &:hover {
      border: 2px solid $freelancer;
    }

    &:nth-child(1) {
      margin-left: 0 !important;
    }

    &:nth-child(odd) {
      margin-left: 0;

      @media only screen and (min-width: 992px) {
        margin-left: 5px;
      }
    }

    &:nth-child(even) {
      margin-right: 0;

      @media only screen and (min-width: 992px) {
        margin-right: 5px;
      }
    }
  }

  &__clear {
    display: inline-block;
    color: #cecece;
    font-size: 14px;
    font-weight: 700;
    margin-top: 10px;
    pointer-events: none;

    &__active {
      color: $freelancer;
      cursor: pointer;
      pointer-events: initial;
    }

    &__active:hover {
      text-decoration: underline;
    }
  }
}

.share {
  @media only screen and (min-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.job-type,
.opportunity-location {
  &__card {
    &:nth-child(4) {
      margin-left: 0 !important;
    }
  }

  @media only screen and (min-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.opportunity-type,
.compensation {
  @media only screen and (min-width: 992px) {
    grid-template-columns: repeat(4, 1fr);
  }
}

// .active {
//   background-color: #237b9f;
//   color: #fff;
// }

.active-purple {
  background-color: #8c79b5;
  color: #fff;
}

.publish,
.publish-center,
.opp-search {
  background-color: #237b9f;
  color: #fff;
  padding: 10px;
  width: 80%;
}
.opp-search {
  padding: 7px;
  width: 100%;
}
.publish-center {
  width: 204px;
}

.add-card {
  background: $freelancer-light;
  padding: 20px;
  font-size: 16px;
  text-align: center;
  margin-top: 20px;
  transition: 200ms all ease-in-out;
  cursor: pointer;
  border: 2px solid $freelancer-light;

  &:hover {
    border: 2px solid $freelancer;
  }
}

.react-date-picker {
  &__wrapper {
    border: none !important;
  }
}

.work-hour {
  width: 100%;
  display: grid;
  box-sizing: border-box;

  @media only screen and (min-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }
}
