.company-switch-menu {
  --avatar-size: 48px;
  --other-team-avatar-size: 20px;
  position: relative;
  line-height: 1;

  @include mqup(lg) {
    position: unset;
  }

  &--popup {
    position: absolute;
    top: calc(100% - 60px + 14px);
    // right: -61px;
    right: 15px;
    // width: 290px;
    width: calc(100% - 30px);

    @include mq(lg) {
      top: calc(100% + 5px);
      width: 274px;
      right: 0; 
    }

    .app-icon-close {
      font-size: 15px;
    }

    .popup--content {
      border-radius: 22px;
      background-color: #fff;
      padding: 27px;
      position: relative;

      .meta-information {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        color: var(--text-secondary-color);
        .meta-avatar {
          border-radius: 999px;
          width: var(--avatar-size);
          height: var(--avatar-size);
          overflow: hidden;
          flex: 0 0 auto;

          & > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .meta-details {
          padding-left: 12px;

          .meta--company-name {
            font-size: 18px;
            font-weight: 900;
            line-height: 26px;
            margin-bottom: 10px;
          }
          .meta--name {
            font-size: 18px;
            font-weight: 600;
            line-height: 13px;
          }
        }
      }

      .meta-companies {
        margin-top: 32px;
        &--label {
          font-size: 14px;
          font-weight: 600;
          line-height: 10px;
          margin-bottom: 10px;
          color: var(--text-secondary-color);
        }

        &--list {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          &-item {
            display: flex;
            justify-content: flex-start;
            flex: 0 0 auto;
            max-width: 100%;
            align-items: center;
            padding: 3px 15px 3px 3px;
            gap: 10px;
            border-radius: 27px;
            border: 1px solid #0e0e0ee0;
            color: var(--text-secondary-color);
          }
        }

        &--break-line {
          flex: 0 0 100%;
          width: 100%;
          margin-top: 8px;

          &:last-child {
            margin-top: 0;
          }
        }

        .list-item {
          &--avatar {
            width: var(--other-team-avatar-size);
            height: var(--other-team-avatar-size);
          }
          &--name {
            font-size: 14px;
            font-weight: 400;
            line-height: 10px;
          }
        }
      } // END of meta-companies
    }
  }
}
