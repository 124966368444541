.notification {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: #EB605D;
    color: #FFFFFF;
    font-size: 11px;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    position: relative;
    top: -10px;
    line-height: 11px;
}
