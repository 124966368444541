.resource {
    margin-bottom: 50px;

    @media only screen and (min-width: 576px) {
        margin-bottom: 30px;
    }

    @media only screen and (min-width: 768px) {
        margin-bottom: 50px;
    }
    &__title {
        font-weight: 700;
        font-size: 18px;
        margin-top: 5px;
        line-height: 24px;
    }
    &__img {
        height: 200px;
        background-size: cover;
        background-repeat: no-repeat;
    }
}
.resources {
    &__card {
        position: relative;
        margin-bottom: 50px;
        overflow: hidden;
        @include mobile{
            margin-bottom: 30px;
        }
        &__title {
            position: absolute;
            bottom: 40px;
            left: 40px;
            margin-right: 25px;
            font-weight: 600;
            font-size: 30px;
            line-height: 34px;
            color: #ffffff;
            z-index: 9;
            @include mobile {
                font-size: 20px;
                line-height: 26px;
                bottom: 30px;
                left: 30px;
                margin-right: 30px;
            }
        }
    }
}
