.form {
  &-content {
    margin-top: 36px;

    @include mq(lg) {
      margin-top: 40px;
    }
  }
  &-block {
    --field-font-size: 14px;
    --field-font-weight: 700;
    --field-line-height: 24px;
    --field-letter-spacing: 0.1;

    @include mq(lg) {
      --field-font-size: 16px;
    }

    hr {
      border-color: var(--btn-text-color);
    }

    display: flex;
    flex-direction: column;

    &.space-t {
      margin-top: 26px;
    }

    &.inline {
      --space-x: 6px;
      --minus-x: -1;
      margin-top: 26px;

      .form-field-row {
        display: flex;
        flex-direction: column;

        @include mq(lg) {
          flex-direction: row;
          margin-right: calc(var(--space-x) * var(--minus-x));
          margin-left: calc(var(--space-x) * var(--minus-x));

          .form-block--item {
            flex: 1 1 0;
            padding-left: var(--space-x);
            padding-right: var(--space-x);

            & + .form-block--item {
              margin-top: 0;
            }

            .field {
              flex: 0 0 auto;
            }
          }
        }
      }

      &--cta {
        width: auto;
        & > .form-field-row {
          & > *:last-child {
            margin-top: 12px;
            @include mq(lg) {
              flex: 0 0 auto;
              width: auto;
              margin-top: 0;
              button {
                width: auto;
              }
            }
            button {
              width: 100%;
            }
          }
        }
      }
    }

    .app-label {
      flex: 0 0 auto;
      color: var(--text-primary-color);
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 6px;
      font-weight: 500;
      padding-left: 8px;

      @include mq(lg) {
        font-size: 16px;
      }

      &.as-blocker {
        visibility: hidden;
        display: none;
        @include mq(lg) {
          display: block;
        }
      }
    }

    &--item {
      display: flex;
      flex-direction: column;
      flex: 0 0 100%;

      .field {
        flex: 0 0 100%;

        &.d-flex {
          display: flex;
          justify-content: center;
        }

        & > input,
        & > textarea,
        .field-input {
          width: 100%;
          background-color: var(--input-bg-color);
          color: var(--input-text-color);
          border: 1px solid var(--input-bg-color);
          border-radius: var(--radius-sm);
          padding: 5px 18px;
          height: 43px;
          font-family: var(--font-body);
          font-size: 16px;
          font-weight: 700;
          line-height: 23.12px;
          letter-spacing: 0.1px;

          @include mq(lg) {
            padding: 5px 21px;
          }

          &--pull-left {
            padding-left: 87px;
          }

          &--variant-rounded {
            border-radius: var(--radius-lg);
            height: 32px;
            padding-right: 15px;
            padding-left: 15px;

            & ~ .app-dropdown--list-wrapper {
              top: 22px;
            }
            & ~ .app-dropdown--list-container {
              padding-right: 15px;
              padding-left: 15px;
            }
          }

          &[max-width='132'] {
            max-width: 132px;

            & ~ .app-dropdown--list-wrapper {
              max-width: 132px;
              min-width: unset;
              width: 100%;
            }
          }
        }

        & > input,
        & > select {
          height: 43px;
        }

        & > textarea.field-input {
          height: 260px;
          @include mq(lg) {
            height: 223px;
          }
        }

        &-add-ons {
          .warn-text {
            flex: 0 0 50%;
            margin-top: 10px;

            &:empty {
              margin-top: 0;
            }
          }
          .help-text {
            flex: 0 0 50%;
            margin-top: 12px;
            font-size: 13px;
            line-height: 9px;
            font-weight: 400;
            letter-spacing: 0.1px;
            text-align: right;

            @include mq(lg) {
              font-size: 14px;
              line-height: 10px;
            }

            &:empty {
              margin-top: 0;
            }
          }
        }

        .field-input-row {
          display: flex;
          align-items: center;

          & > .field-input {
            width: 18px;
            flex: 0 0 auto;
          }

          & > * {
            flex: 0 0 auto;
            margin-bottom: 0;
            & + * {
              margin-left: 5px;
            }
          }
        }
      }

      &.inline {
        flex-direction: row;
        flex: 1 1 0;
      }

      & + * {
        margin-top: 26px;
      }

      .search-wrapper {
        background-color: transparent;
        color: var(--input-text-color);
        border: none;
        border-radius: var(--radius-sm);
        padding: 50px 0 0;
        line-height: 1em;
        letter-spacing: 0.01em;
        display: flex;
        flex-wrap: wrap;

        @include mq(lg) {
          padding: 59px 0 0;
        }

        & > input {
          position: absolute;
          top: 0;
          left: 0;
          background: white;
          padding: 10px 21px;
          border-radius: var(--radius-sm);
          font-family: var(--font-body);
          font-size: 16px;
          font-weight: 500;
          line-height: 23.12px;
          letter-spacing: 0.1px;
          margin-top: 0;

          &::placeholder {
            color: var(--input-placeholder-color);
            opacity: 1;
          }
        }
      }

      .optionListContainer {
        border-width: 0;
        transform: translate(-14px);
        border-color: unset;
        box-shadow: unset;
        border-radius: 0 0 var(--radius-lg) var(--radius-lg);
        background-color: var(--secondary-color);
        position: absolute;
        width: 100%;
        margin-top: 1px;
        z-index: 12;
        left: 14px;
        top: 36px;
        font-family: var(--font-body);
        font-size: 16px;
        font-weight: 500;
        line-height: 23.12px;
        letter-spacing: 0.1px;
        overflow: hidden;

        & > ul {
          border-radius: 0;
          border: unset;
          padding: 12px;
          list-style: none;
          margin: 0;

          li {
            border-bottom: 1px solid var(--primary-color);
            color: var(--text-secondary-color);

            &:last-child {
              border-bottom: unset;
            }

            &:hover {
              background: black;
              color: white;
              opacity: 1;
            }
          }
          & > span {
            color: var(--text-secondary-color);
          }
        }
      }

      .chip {
        display: flex;
        padding: 4px 14px 3px;
        color: var(--text-primary-color);
        border: 1px solid var(--text-primary-color);
        border-radius: 100px;
        line-height: 8px;
        font-size: 14px;
        letter-spacing: 0;
        transition: var(--t-duration);
        justify-content: center;
        align-items: center;
        background-color: var(--primary-color);
        flex-direction: row-reverse;

        [class^='icon_'] {
          margin-right: 6px;
          margin-left: -8px;
          &:before {
            content: '';
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8.55469' r='7.5' stroke='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.1416 5.1473L8.72136 8.54183L8.72145 8.54192L12.1417 5.14739L12.1416 5.1473ZM12.1416 11.964L11.5509 12.5547L8.15544 9.13347L4.73422 12.5547L4.14267 11.9631L4.14169 11.9641L4.73333 12.5558L8.15455 9.13453L11.55 12.5558L12.1417 11.9641L12.1416 11.964ZM7.56282 8.5428L4.14169 5.14739L4.14267 5.14641L7.5638 8.54183L7.56282 8.5428ZM4 5.14766L7.42122 8.54316L4.00027 11.9641L4.73333 12.6972L8.15428 9.27622L11.5498 12.6974L12.2831 11.9641L8.86215 8.54316L12.2834 5.14766L11.5498 4.41406L8.15428 7.83528L4.73333 4.41433L4 5.14766Z' fill='white'/%3E%3C/svg%3E%0A");
            width: 16px;
            height: 17px;
          }
        }
      }

      .react-date-picker {
        &__calendar {
          width: 100%;
          z-index: 20 !important;

          .react-calendar {
            width: 100%;
            border-radius: 10px;
            border: 1px solid var(--input-text-color);
            font-family: var(--font-body);

            &__tile {
              &--now,
              &--active {
                border-radius: 10px;
              }
            }

            button {
              &:hover {
                border-radius: 10px;
              }
            }
          }
        }

        border-radius: 12px;
        padding: 5px 18px;
        height: 43px;
        font-family: var(--font-body);
        font-size: 16px;
        font-weight: 700;
        line-height: 23.12px;
        letter-spacing: 0.1px;
        background-color: var(--input-bg-color);
        color: var(--input-text-color);
        border: 1px solid var(--input-bg-color);
        border-radius: var(--radius-sm);

        &__inputGroup {
          &__divider {
            color: var(--input-text-color);
          }

          &__input {
            padding: 0;
            margin: 0 3px;
          }
        }
      }

      .upload-field::file-selector-button {
        padding: 14px 25px;
        border-radius: var(--radius-lg);
        border: none;
        font-weight: bold;
        font-size: 14px;
        transition: transform var(--t-duration);
        transform: scale(1);
        text-align: center;

        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: var(--btn-text-color);
        background-image: var(--btn-bg);
        background-repeat: no-repeat;
        background-color: var(--third-gradient-color);
        background-position: center;
        background-size: 102% 102%;
      }

      mapbox-search-box {
        input {
          height: 43px;
          padding: 0 21px;

          &::placeholder {
            color: #555555;
          }
        }
        button {
          display: none !important;
        }
      }
    }

    .phone-button {
      border: none !important;
      background: var(--phone-input-color) !important;
      margin: 4px !important;
      border-radius: 9px !important;
      padding-inline: 0 !important;
      width: 70px !important;
      .arrow {
        display: none !important;
      }
      .country:hover {
        background-color: var(--phone-input-color) !important;
        color: white !important;
      }
      .country:hover .dial-code {
        color: white !important;
      }
      .selected-flag {
        background-color: unset !important;
        padding: 0 !important;
        width: 100% !important;
        padding-inline: 18px !important;
        &::after {
          content: '';
          background-image: url('/img/chevron-down.svg') !important;
          background-position: 70% !important;
          background-repeat: no-repeat !important;
          display: block;
          width: 10px;
          height: 10px;
          position: absolute;
          right: 18px;
          top: 40%;
          transition: all 0.3s ease-in-out;
        }
      }
      .selected-flag.open {
        &::after {
          transform: rotate(180deg);
        }
      }
      .selected-flag:hover {
        background-color: unset !important;
      }
      .country-list .country.active {
        background-color: unset;
        color: unset;
      }
      .country-list .country.highlight .dial-code {
        color: white !important;
      }
      .country-list .country.highlight {
        background-color: black !important;
        color: white !important;
      }
    }
  }
}

.form-cta {
  --cta-space-y: 28px;
  --cta-space-extra-y: 48px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;

  @include mq(lg) {
    --cta-space-y: 40px;
    --cta-space-extra-y: 62px;
  }

  &.j-center {
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  &.space-t {
    margin-top: var(--cta-space-y);

    &--extra {
      margin-top: var(--cta-space-extra-y);
    }
  }

  &.style-column {
    flex-direction: column;
    & > * {
      width: 100%;
      flex: 0 0 auto;

      & + * {
        margin-top: 12px;
      }
    }
  }

  .app-btn-submit {
    flex: 0 0 100%;
  }

  &.half-by-half {
    align-items: center;
    & > * {
      width: 100%;
      @include mq(lg) {
        flex: 0 0 50%;
        max-width: 50%;
      }

      & > button {
        width: 100%;
      }

      &.space-x {
        &.thin {
          margin-top: 10px;
          @include mq(lg) {
            margin-top: 0;
            padding-left: 4px;
          }
        }
      }
    }
  }

  &.sidebyside {
    --gap: 12px;
    flex-direction: column;

    @include mq(lg) {
      flex-direction: row;
    }

    & * + * {
      margin-top: var(--gap);
      margin-left: 0;

      @include mq(lg) {
        margin-top: 0;
        margin-left: var(--gap);
      }
    }
    & > button {
      flex: 1 1 0;
    }
  }
}

.form-more-action {
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  @include mq(lg) {
    margin-top: 26px;
  }

  .form-google-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .g-title {
      font-size: 14px;
      font-weight: 500;
      line-height: 16.09px;
      letter-spacing: 0.067px;
      text-align: center;
    }

    .g-circle {
      --bg-color: #4285f4;
      border-radius: 100px;
      height: 32px;
      width: 32px;
      padding: 0;
      border-color: var(--bg-color);
      border-width: 0;
      border-style: solid;
      display: flex;
      justify-content: center;
      background-color: var(--bg-color);
      color: var(--text-primary-color);
      align-items: center;
      margin-top: 12px;
      background-image: none;
    }

    & + * {
      margin-top: 30px;
      text-align: center;

      @include mq(lg) {
        margin-top: 40px;
      }
    }
  }

  .jump-to {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.1px;
  }
}

/* The container must be positioned relative: */
.form-control-select {
  position: relative;
  flex: 0 0 100%;

  & > .chevron-icon {
    position: absolute;
    font-size: 16px;
    right: 18px;
    top: 8px;

    @include mq(lg) {
      right: 21px;
      top: 8px;
    }
  }

  & > select {
    appearance: none;
    width: 100%;
    font-size: 1.15rem;
    background-color: var(--secondary-color);
    border: 1px solid var(--secondary-color);
    border-radius: var(--radius-lg);
    color: var(--input-text-color);
    cursor: pointer;
  }
}

.form-uploader-block {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  height: 100%;
  width: 100%;

  & > .up-container {
    height: 100%;
  }

  .up-row {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: rgba(142, 142, 142, 0.2);
    border-radius: var(--radius-lg);

    & > img {
      overflow: auto;
      width: 100%;
      height: auto;
      object-fit: cover;

      &.full-rounded {
        border-radius: 999px;
      }
    }

    .up-icon-cyrcle {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 86px;
      width: 86px;
      border-radius: 86px;
      background: radial-gradient(
        74.13% 291.65% at 25.87% 81.1%,
        var(--third-gradient-color) 0%,
        var(--first-gradient-color) 100%
      );
      margin-bottom: 21px;

      &.size-xs {
        width: 58px;
        height: 58px;
      }
    }
  }
}

.step-counter {
  display: flex;
  justify-content: center;
  margin-bottom: var(--layout-space-x);

  & > .step-number {
    flex: 0 0 auto;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: unset;
    background-image: unset;
    background-color: var(--secondary-color);
    color: var(--text-secondary-color);

    &.active {
      background-image: var(--btn-bg);
      background-size: 40px 40px;
      background-repeat: no-repeat;
      color: var(--text-primary-color);
    }

    & + .step-number {
      margin-left: 16px;
    }
  }
}

.frame-substract {
  display: block;
  position: absolute;
  top: 0;
  left: 0;

  &.no-frame {
    width: 100%;
    height: 100%;

    .app-btn-modal-close {
      right: 8px;
      top: 8px;
      left: unset;
    }
  }
}

.form-uploader-block {
  .up-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 20.23px;
    letter-spacing: 0.001em;
    text-align: center;

    i,
    b {
      font-weight: 700;
    }
  }
}

.radio-gradient:checked {
  &:before {
    background: none !important;
  }
  background-image: var(--switch-bg);
  background-position: left;
  background-size: cover;
}

.radio-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  border: none;
  font-weight: bold;
  font-size: 14px;
  transition: transform var(--t-duration);
  transform: scale(1);
  text-align: center;
  position: relative;
  overflow: hidden;

  .radio-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    color: var(--btn-text-color);
    background-repeat: no-repeat;
    background-image: unset;
    background-color: var(--primary-color);
    background-position: center;
    background-size: 102% 102%;
    z-index: 1;
    border-width: 1px;
    border-style: solid;
    border-color: #fff;
    border-radius: var(--radius-lg);
    margin-left: 0 !important;
  }

  .radio-text {
    position: relative;
    z-index: 2;
  }

  @include mq(lg) {
    font-size: 16px;
  }

  input {
    position: absolute;
    opacity: 0;

    &:checked ~ .radio-bg {
      border-width: 0;
      background-image: var(--btn-bg);
      background-color: var(--third-gradient-color);
    }
  }
}

label.error-state {
  .tnc {
    color: var(--error-primary);
    a {
      color: var(--error-primary);
    }
  }
}

.tnc {
  a {
    text-decoration: underline;
  }
}

.multiselect-no-chip {
  .multiselect-container {
    .search-wrapper {
      .chip {
        display: none;
      }
    }
  }
}

.multiselect-container {
  .icon_down_dir {
    top: 8px;
    transform: translateY(0);
    right: 23px;
  }

  .icon_down_dir::before {
    display: none;
  }
}

.multiselect-with-icon {
  .search-wrapper {
    .searchBox {
      padding-left: 40px;
    }
    & > input {
      font-weight: 400;
    }
  }
  .app-icon-search {
    margin-top: -3px;
    position: absolute;
    left: 29px;
    z-index: 2;
    top: 17px;
  }
}

.form-block--item {
  .form__input.StripeElement {
    border-radius: 9px;
    background-color: white;
    height: 43px;
    padding-top: 13px;
    font-family: var(--font-body);
    font-weight: bold;
  }
}

.app-form-half {
  max-width: 439px;
}
.dekstop-max {
  @include mq(lg) {
    &[data-size='143'] {
      max-width: 143px;
    }
  }
}

.phone-input-dropdown .active {
  background-color: inherit;
  color: inherit;
}

.app-custom-checkbox {
  --size: 12px;
  --bg-color: #1e1e1e;
  --border-color: #ffffff;
  display: flex;
  align-items: center;
  margin-bottom: 0;

  & > input {
    display: none;
    &:checked {
      & ~ .block-box {
        @extend .display-bg-gradient;
      }
    }
  }

  .block-box {
    width: var(--size);
    height: var(--size);
    border-radius: 3px;
    border: 1px solid;
    display: block;
    background-image: unset;
  }

  .text-label {
    margin-left: 10px;
    display: block;
    font-size: 14px;
    font-weight: 500;
    line-height: 10px;
    text-align: left;
  }
}

.tabler-checkbox-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  .field-add-ons {
    display: none;
  }
}

.field-input {
  &:disabled {
    opacity: 0.5;
  }
}

.error-state ~ .block-box {
  border-color: var(--error-primary);
}
.error-state ~ .text-label {
  color: var(--error-primary);
  a {
    color: var(--error-primary);
  }
}

.optionListContainer {
  border-width: 0;
  transform: translate(-14px);
  border-color: unset;
  box-shadow: unset;
  border-radius: 0 0 var(--radius-lg) var(--radius-lg);
  background-color: var(--secondary-color);
  position: absolute;
  width: 100%;
  margin-top: 1px;
  z-index: 12;
  left: 14px;
  top: 36px;
  font-family: var(--font-body);
  font-size: 16px;
  font-weight: 500;
  line-height: 23.12px;
  letter-spacing: 0.1px;
  overflow: hidden;

  & > ul {
    border-radius: 0;
    border: unset;
    padding: 12px;
    list-style: none;
    margin: 0;

    li {
      border-bottom: 1px solid var(--primary-color);
      color: var(--text-secondary-color);

      &:last-child {
        border-bottom: unset;
      }

      &:hover {
        background: black;
        opacity: 1;
        color: white;
      }
    }
    & > span {
      color: var(--text-secondary-color);
    }
  }
}

.custom-multiselect {
  &[data-haslefticon='true'] {
    .optionListContainer {
      width: calc(100% + 29px);
      left: -15px;
    }
  }
}

.multiSelectContainer {
  input {
    border: 1px solid var(--input-bg-color) !important;
  }
  &.error-state {
    .search-wrapper {
      input {
        border-width: 4px 4px 3px !important;
        border-color: var(--error-primary) !important;
        color: var(--error-primary);
        background-color: var(--error-accent);
      }
      .icon_down_dir {
        top: 12px;
      }
    }
  }
}
