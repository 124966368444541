        .footer {
            background-color: #0E0E0E;
            color: #FFFFFF;
            margin-top: 100px;
            padding-top: 50px;
            padding-bottom: 100px;
            &__inner {
                margin-bottom: 50px;
                text-align: center;
                @media only screen and (min-width: 768px) {
                    text-align: left;
                    width: 20%;
                }
                &__title {
                    font-size: 20px;
                    font-weight: 700;
                    margin-bottom: 10px;
                    letter-spacing: 1px;
                }
                &__content {
                    @media only screen and (min-width: 768px) {
                        text-align: left;
                    }
                    &__ul {
                        display: flex;
                        flex-direction: column;
                        list-style-type: none;
                        padding-inline-start: 0px;
                        &__li {
                            text-align: center;
                            @media only screen and (min-width: 768px) {
                                text-align: left;
                            }
                        }
                    }
                }
                &__smallprint {
                    margin-top: 20px;
                    font-size: 14px;
                }
            }
            &__inner:last-of-type {
                margin-bottom: 0px;
            }
            &__links {
                color: #FFFFFF;
                text-decoration: none;
                &__img {
                    margin-right: 10px;
                }
                &__img:last-of-type {
                    margin-right: 0px;
                }
            }
            &__links:hover {
                text-decoration: underline;
            }
        }
        
        .footer .container {
            @media only screen and (min-width: 768px) {
                display: flex;
                flex-direction: row;
                word-break: break-word;
                padding-right: 60px;
            }
        }